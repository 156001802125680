import * as animator from '../redux/animator/redux'
import * as animators from '../redux/animators/redux'
import * as auth from '../redux/auth/redux'
import * as app from '../redux/app/redux'
import * as appConfig from '../redux/appConfig/redux'
import * as candidates from '../redux/candidates/redux'
import * as colleges from '../redux/colleges/redux'
import * as commonUsers from '../redux/commonUsers/redux'
import * as configuration from '../redux/configuration/redux'
import * as control from '../redux/control/redux'
import * as demo from '../redux/demo/redux'
import * as establishment from '../redux/establishment/redux'
import * as establishments from '../redux/establishments/redux'
import * as files from '../redux/files/redux'
import * as helpFiles from '../redux/helpFiles/redux'
import * as lists from '../redux/lists/redux'
import * as listPolls from '../redux/listPolls/redux'
import * as logs from '../redux/logs/redux'
import * as mePolls from '../redux/mePolls/redux'
import * as modals from '../redux/modals/redux'
import * as pgpKeys from '../redux/pgpKeys/redux'
import * as profile from '../redux/profile/redux'
import * as proxies from '../redux/proxies/redux'
import * as receipt from '../redux/receipt/redux'
import * as records from '../redux/records/redux'
import * as resolutions from '../redux/resolutions/redux'
import * as roles from '../redux/roles/redux'
import * as scrutins from '../redux/scrutins/redux'
import * as sealing from '../redux/sealing/redux'
import * as sendCodes from '../redux/sendCodes/redux'
import * as signatures from '../redux/signatures/redux'
import * as time from '../redux/time/redux'
import * as theme from '../redux/theme/redux'
import * as uninominal from '../redux/uninominal/redux'
import * as user from '../redux/user/redux'
import * as userResolutions from '../redux/userResolutions/redux'
import * as users from '../redux/users/redux'
import * as vote from '../redux/vote/redux'
import * as mixedPoll from '../redux/mixedPoll/redux'

const entities = {
  animator,
  animators,
  app,
  appConfig,
  auth,
  candidates,
  colleges,
  commonUsers,
  configuration,
  control,
  demo,
  establishment,
  establishments,
  files,
  helpFiles,
  lists,
  listPolls,
  logs,
  mePolls,
  mixedPoll,
  modals,
  pgpKeys,
  profile,
  proxies,
  receipt,
  records,
  resolutions,
  roles,
  scrutins,
  sealing,
  sendCodes,
  signatures,
  time,
  theme,
  uninominal,
  user,
  userResolutions,
  users,
  vote,
}

export default entities
