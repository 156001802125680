import { createAsyncThunk } from '@reduxjs/toolkit'
import { CIVILITY_DISPLAY, SEX_DISPLAY } from '../../locales/constants'
import jsonToString from '../../utils/lib/jsonToString'
import { deleteAxios, getAxios, getTokenHeaders, setAxios } from '../../utils/lib/requestAxios'
import { requestCommonUsers } from '../commonUsers/services'
import { requestRandomRole, requestUsers as requestDemoUsers } from '../demo/services'
import { requestProfile } from '../profile/services'
import { requestCandidates } from '../scrutins/services'
import { MAIN_ROLES } from '../roles/constants'

const addUser = (state, user, userId) => {
  if (user?.user) {
    user = user?.user
  }
  if (user?.id === state.userId && !!state.userById[userId]) return state.userById[userId]
  return {
    userId,
    username: user?.login,
    pic: user?.avatar,
    ...user,
    college: { type: `college_${user?.college?.type}` },
    sex: SEX_DISPLAY[user?.sex],
    civility: CIVILITY_DISPLAY[user?.civility],
  }
}

// Users request by search or/and by roleIds
export const requestUsers = createAsyncThunk<any, any>(
  'users/requestUsers',
  async ({ search, params = {}, clear = true }, { rejectWithValue }) => {
    const response: any = await getAxios(
      `/users?${jsonToString({ search, ...params })}`,
      getTokenHeaders()
    )

    if (response.error) {
      return rejectWithValue(response.error.response.status)
    } else {
      return {
        data: response.data,
        clear,
      }
    }
  }
)

export const requestUsersStates = (builder) => {
  builder.addCase(requestUsers.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(requestUsers.fulfilled, (state, { payload }) => {
    const { data, clear } = payload
    const { users, count } = data
    state.isLoading = false
    state.totalUsers = count

    if (clear) {
      state.userIds = []
    }
    users.forEach((user) => {
      const { id: userId } = user
      if (!state.userIds.includes(userId)) {
        state.userIds.push(userId)
      }
      state.userById[userId] = addUser(state, user, userId)
    })
  })

  builder.addCase(requestUsers.rejected, (state) => {
    state.isLoading = false
  })
}

// Request 1 User
export const requestUser = createAsyncThunk<any, any>(
  'users/requestUser',
  async ({ userId }, { rejectWithValue }) => {
    const response: any = await getAxios(`/users/${userId}`, getTokenHeaders(true))

    if (response.error) {
      return rejectWithValue(response.error.response.status)
    } else {
      return response.data
    }
  }
)

export const requestUserStates = (builder) => {
  builder.addCase(requestUser.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(requestUser.fulfilled, (state, { payload: user }) => {
    const { id: userId } = user
    state.userById[userId] = addUser(state, user, userId)
  })

  builder.addCase(requestUser.rejected, (state) => {
    state.isLoading = false
  })
}

// State to react to profile load
export const requestProfileStates = (builder) => {
  builder.addCase(requestProfile.fulfilled, (state, { payload: user }) => {
    const { id } = user
    state.userById[id] = addUser(state, user, id)
    state.userId = id
  })
}

// Request Filters
export const requestFilters = createAsyncThunk<any, undefined>('users/requestFilters', async () => {
  const responseEtablishment: any = await getAxios('/establishments', getTokenHeaders())
  const responseColleges: any = await getAxios('/colleges/types', getTokenHeaders())

  return {
    establishments: responseEtablishment.error ? {} : responseEtablishment.data,
    colleges: responseColleges.error ? {} : responseColleges.data,
  }
})

export const requestFiltersStates = (builder) => {
  builder.addCase(requestFilters.fulfilled, (state, { payload: filters }) => {
    state.filters.colleges = filters.colleges.map((value) => ({
      label: `college_${value}`,
      value,
    }))
    state.filters.establishments = filters.establishments?.map((item) => ({
      label: item.name,
      value: item.id,
    }))
  })

  builder.addCase(requestFilters.rejected, (state) => {
    state.filters = {}
  })
}

// Delete Users

export const requestDeleteUser = createAsyncThunk<any, { userId: string }>(
  'users/requestDeleteUser',
  async ({ userId }, _) => {
    await deleteAxios(`/users/${userId}`, getTokenHeaders())
    window.location.reload()
  }
)

// For Demo users
export const requestDemoUsersStates = (builder) => {
  builder.addCase(requestDemoUsers.fulfilled, (state, { payload }) => {
    const { data } = payload
    const { users } = data

    users.forEach((user) => {
      const { id: userId } = user
      state.userById[userId] = addUser(state, user, userId)
    })
  })
}

export const requestRandomRoleStates = (builder) => {
  builder.addCase(requestRandomRole.fulfilled, (state, { payload: user }) => {
    const { id: userId } = user
    state.userById[userId] = addUser(state, user, userId)
  })
}

export const requestSetUsersRole = createAsyncThunk<any, any>(
  'users/setUsersRole',
  async ({ role, userIds }, { rejectWithValue, dispatch }) => {
    const response: any = await setAxios(`/users/role/${role}`, { userIds }, getTokenHeaders())

    if (response.error) {
      return rejectWithValue(response.error.response.status)
    } else {
      let firstLoop = true
      MAIN_ROLES.forEach((value, index) => {
        if (value !== 'ADMIN') {
          dispatch(requestDemoUsers({ search: '', roles: [value], params: {}, clear: firstLoop }))
          firstLoop = false
        }
      })
      return { role, userIds }
    }
  }
)

export const requestSetUsersRoleStates = (builder) => {
  builder.addCase(requestSetUsersRole.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(requestSetUsersRole.fulfilled, (state, { payload }) => {
    const { role, userIds } = payload
    userIds.forEach((userId) => {
      state.userById[userId].role = role
    })
    state.isLoading = false
  })

  builder.addCase(requestSetUsersRole.rejected, (state) => {
    state.isLoading = false
  })
}

// Fillers from other services
export const requestCandidatesStates = (builder) => {
  builder.addCase(requestCandidates.fulfilled, (state, { payload }) => {
    const { data } = payload
    data.forEach((candidate: any = { user: { id: 0 } }) => {
      const user = candidate.user || { id: 0 }
      state.userById[user?.id] = addUser(state, user, user?.id)
    })
  })
}
export const requestCommonStates = (builder) => {
  builder.addCase(requestCommonUsers.fulfilled, (state, { payload }) => {
    if (!payload) return

    const { data } = payload
    let { users } = data || { users: [] }
    if (data.users === undefined) {
      users = data
    }

    users.forEach((user) => {
      state.userById[user?.id] = addUser(state, user, user?.id)
    })
  })
}
