import { DEFAULT_TIMEZONE, toStringDate } from '../date-time.utils'

export const getDistance = (today: number, closesAt: number) => {
  // get total seconds between the times
  let delta = Math.abs(closesAt - Number(today)) / 1000

  // calculate (and subtract) whole days
  const days = Math.floor(delta / 86400)
  delta -= days * 86400

  // calculate (and subtract) whole hours
  const hours = Math.floor(delta / 3600) % 24
  delta -= hours * 3600

  // calculate whole minutes
  const minutes = Math.floor(delta / 60) % 60

  return {
    daysLeft: days,
    minutes,
    hours,
  }
}

export const getTimes = (
  openAt: Date,
  closesAt: Date,
  timezone: string = DEFAULT_TIMEZONE,
  displayTimezone = true
) => {
  return {
    openDate: toStringDate(openAt, 'DD/MM/YYYY [à] HH[h]mm', timezone, displayTimezone),
    closeDate: toStringDate(closesAt, 'DD/MM/YYYY [à] HH[h]mm', timezone, displayTimezone),
    timezone: timezone,
  }
}
