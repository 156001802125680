import { createSlice } from '@reduxjs/toolkit'

// Services
import { requestExportStates, requestImportStates, requestImportStatesFinished } from './services'

// Utils

// Define a type for the slice state
export type FilesState = {
  status: boolean
  percentage: number
  error?: string
  isWarn?: boolean
  success?: boolean
  statusWording?: string | null
}

// Define the initial state using that type
const initialState: FilesState = {
  status: false,
  percentage: 0,
}

export const files = createSlice({
  name: 'files',
  initialState,
  reducers: {
    setSuccess: (state, { payload: value }) => {
      state.success = value
      state.percentage = !value ? 0 : 100
      state.isWarn = !!value
    },
    setError: (state, { payload: value }) => {
      state.error = value
      if (!value) {
        state.isWarn = false
      }
    },
    setPercentage: (state, { payload: value }) => {
      state.percentage = value
    },

    setStatusWording: (state, { payload: value }) => {
      state.statusWording = value
    },
  },
  extraReducers: (builder) => {
    requestImportStates(builder)
    requestExportStates(builder)
    requestImportStatesFinished(builder)
  },
})

export const { setError, setSuccess, setPercentage, setStatusWording } = files.actions
