import { store } from './init/store'
import { Provider } from 'react-redux'
import { BrowserRouter, Switch } from 'react-router-dom'
import { IonReactRouter } from '@ionic/react-router'
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react'
import * as Sentry from '@sentry/react'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'

/* Theme variables */
import './theme/variables.scss'
import './theme/global.scss'

// Router
import { Suspense } from 'react'
import Router from './routes/Router/Router'
import isIFrame from './utils/lib/isIFrame'

setupIonicReact()

window.onbeforeunload = (ev) => {
  if (!isIFrame) sessionStorage.clear()
}

const App: React.FC = () => (
  <Sentry.ErrorBoundary>
    <Provider store={store}>
      <IonApp>
        <Suspense fallback={<div />}>
          <IonReactRouter>
            <IonRouterOutlet>
              <BrowserRouter>
                <Switch>
                  <IonReactRouter>
                    <Router />
                  </IonReactRouter>
                </Switch>
              </BrowserRouter>
            </IonRouterOutlet>
          </IonReactRouter>
        </Suspense>
      </IonApp>
    </Provider>
  </Sentry.ErrorBoundary>
)

export default App
