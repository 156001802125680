import { createSlice } from '@reduxjs/toolkit'

// Services
import {
  requestLogsInitiatorsStates,
  requestLogsImpactedStates,
  requestUserLogsStates,
} from './services'

// Utils

// Define a type for the slice state
export type LogsState = {
  userLogsById: { [id: string]: any }
  impacted: any[]
  initiators: any[]
}

// Define the initial state using that type
const initialState: LogsState = {
  userLogsById: {},
  impacted: [],
  initiators: [],
}

export const logs = createSlice({
  name: 'logs',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    requestUserLogsStates(builder)
    requestLogsImpactedStates(builder)
    requestLogsInitiatorsStates(builder)
  },
})

//export const { UpdateUser } = users.actions
