import { createSlice } from '@reduxjs/toolkit'

// Services
import { requestManageEstablishmentStates } from './services'

// Utils

// Define a type for the slice state
export type EstablishmentState = {
  isLoading?: boolean
  success?: boolean
  error?: any
}

// Define the initial state using that type
const initialState: EstablishmentState = {}

export const establishment = createSlice({
  name: 'establishment',
  initialState,
  reducers: {
    setSuccess: (state, { payload: value }) => {
      state.success = value
    },
    setError: (state, { payload: value }) => {
      state.error = value
    },
  },
  extraReducers: (builder) => {
    requestManageEstablishmentStates(builder)
  },
})

export const { setError, setSuccess } = establishment.actions
