import ROUTES from '../../constants/routes'
import IconTypes from '../../../utils/types/IconTypes'

export type ToolbarConfigType = {
  name: string
  icon?: string
  path?: string
  children?: ToolbarConfigType[]
  isDisabled?: boolean
  isChild?: boolean
  enabled?: boolean
}

/**
 * Changes the appearance of Sealing status on Toolbar,
 * depending on the current platform state
 */

const getSealData = ({ countDone, isSealed, generatedKeys, privileges }) => {
  if (!countDone) {
    return [
      {
        name: isSealed ? 'unsealing' : 'sealing',
        icon: IconTypes.KEY,
        path: ROUTES.SEALING,
        isDisabled: !generatedKeys,
        enabled: !!privileges.LOCK_URN,
      },
    ]
  }
  return []
}

const toolbarConfig: (props: {
  isSealed?: boolean
  countDone?: boolean
  generatedKeys?: boolean
  privileges: {
    [key: string]: boolean | string
  }
  isCSE?: boolean
}) => ToolbarConfigType[] = ({ isSealed, countDone, generatedKeys, privileges, isCSE }) => [
  {
    name: 'settings',
    icon: IconTypes.TOOL,
    path: ROUTES.CONFIG_POLL,
    enabled: !!privileges.CONFIGURE_ELECTION,
  },
  {
    name: 'role_privileges_settings',
    icon: IconTypes.AWARD,
    path: ROUTES.EDIT_ROLE,
    enabled: !!privileges.EDIT_ROLE,
  },
  {
    name: 'cell_members_bureau',
    icon: IconTypes.USER_KEY,
    path: ROUTES.CELL_MEMBERS,
    enabled: !!privileges.MANAGE_CELL_MEMBERS,
  },
  ...getSealData({ countDone, isSealed, generatedKeys, privileges }),
  {
    name: 'record_list',
    icon: IconTypes.FILE_TEXT,
    path: ROUTES.PV_LIST,
    enabled: !!privileges.ACCESS_MINUTES,
    //allowMod: true,
  },
  {
    name: 'users',
    icon: IconTypes.USERS,
    path: ROUTES.USERS_LIST,
    enabled:
      !!privileges.CONSULT_USER_PROFILE ||
      !!privileges.CONSULT_USER_VOTING_LOG ||
      !!privileges.EDIT_USER_INFORMATION ||
      !!privileges.EDIT_USER_PRIVILEGES ||
      !!privileges.MANAGE_PASSWORD_RECOVERY,
  },
  {
    name: 'establishments',
    icon: IconTypes.ENTERPRISE,
    path: ROUTES.ESTABLISHMENTS,
    enabled: !!privileges.EDIT_ESTABLISHMENTS,
  },
  {
    name: 'polls',
    path: ROUTES.ELECTIONS,
    icon: IconTypes.MAIL,
    enabled:
      !!privileges.CONFIGURE_ELECTION ||
      !!privileges.CONTROL_LISTS ||
      !!privileges.ACCESS_PARTICIPATION ||
      !!privileges.ACCESS_SIGNATURE,
  },
  {
    name: 'voting_logs',
    icon: IconTypes.BOOK_OPEN,
    path: ROUTES.VOTING_LOGS,
    enabled: !!privileges.CONSULT_USER_VOTING_LOG,
  },
  {
    name: 'code_send',
    icon: IconTypes.SEND,
    path: ROUTES.PREVIEW_CODES,
    enabled: !!privileges.MASS_CODE_SEND,
  },
  {
    name: 'follow_passwords',
    icon: IconTypes.RESOLUTION_LIST,
    path: ROUTES.FOLLOW_PASSWORDS,
    enabled: !!privileges.MANAGE_PASSWORD_RECOVERY,
  },
  {
    name: 'handle_help_files',
    icon: IconTypes.HELP_CIRCLE,
    path: ROUTES.HELP_FILES_MANAGEMENT,
    enabled: !!privileges.HELP_FILES_MANAGEMENT,
  },
  {
    name: 'securing',
    icon: IconTypes.DATABASE,
    enabled: !!privileges.PGP_KEYS_MANAGEMENT || !!privileges.CODE_FORMAT_MANAGEMENT,
    children: [
      {
        name: 'handle_codes_formats',
        path: ROUTES.FORMAT_MANAGEMENT,
        enabled: !!privileges.CODE_FORMAT_MANAGEMENT,
      },
      {
        name: 'handle_PGP_keys',
        path: ROUTES.PGP_KEYS_MANAGEMENT,
        enabled: !!privileges.PGP_KEYS_MANAGEMENT,
      },
      {
        name: 'maintenance',
        path: ROUTES.MAINTENANCE,
        enabled: !!privileges.PGP_KEYS_MANAGEMENT,
      },
    ],
  },
]
export default toolbarConfig
