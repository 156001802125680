import React from 'react'
import {
  IonModal,
  IonButton,
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonIcon,
} from '@ionic/react'
import './LegalModal.scss'
import IconTypes from '../../utils/types/IconTypes'
import Icon from '../../atoms/Icon/Icon'

interface LegalModalProps {
  isOpen: boolean
  onClose: () => void
  t: (key: string) => string
}

const LegalModal: React.FC<LegalModalProps> = ({ isOpen, onClose, t }) => {
  return (
    <IonModal id='LegalModal' isOpen={isOpen} onDidDismiss={onClose}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{t('legal_mentions')}</IonTitle>

          <Icon className='icon' icon={IconTypes.X} onClick={onClose} />
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <div className='legalContent'>
          <h2>Sécurité, confidentialité et droit d’accès</h2>
          <p>
            Le dispositif électoral a fait l'objet d’une déclaration à la CNIL et répond aux
            recommandations de cet organisme en matière de sécurité et de confidentialité, en
            particulier à la délibération n°2019-053 portant adoption d'une recommandation relative
            à la sécurité des systèmes de vote électronique.
          </p>
          <p>
            Il comporte des informations nominatives nécessaires au traitement de votre vote dont le
            contenu reste anonyme et secret. Les données personnelles vous concernant constituent
            des données confidentielles qui ne peuvent être ni cédées à des tiers ni utilisées à des
            fins personnelles ou commerciales (R 713-63).
          </p>
          <p>
            Ces informations sont conservées dans des conditions de sécurité appropriées et pendant
            une durée strictement nécessaire à la validation des opérations électorales.
          </p>
          <p>
            Keyvote a fait l'objet d'une déclaration auprès de la CNIL en application des articles
            15 et 16 de la Loi 78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et
            aux libertés sous le numéro de déclarant 1166474. Vous disposez d'un droit d'accès, de
            modification, de rectification et de suppression des données qui vous concernent.
          </p>
          <h2>Responsable éditorial, hébergement et conception</h2>
          <p>
            <strong>INQIPIT</strong>
            <br />
            5-7 avenue du Général de Gaulle
            <br />
            94160 SAINT MANDE
            <br />
            Tél : 01.49.28.53.53
            <br />
            Courriel : contact@inqipit.fr
            <br />
            SIRET : 880 792 296 00035
            <br />
            NAF : 6202 A
          </p>
          <h2>Ce site est hébergé sur la métropole française.</h2>
          <p>
            L'ensemble des éléments figurant sur ce site Web est protégé du Code de la Propriété
            Intellectuelle. En conséquence, toute reproduction de ceux-ci, totale ou partielle, ou
            imitation, sans accord exprès, préalable et écrit, est interdite.
          </p>
          <p>
            Il est formellement interdit de collecter et d’utiliser les informations disponibles sur
            le site à des fins commerciales. Cette interdiction s’étend notamment, sans que cette
            liste ne soit limitative, à tout élément rédactionnel figurant sur le site, à la
            présentation des écrans, aux logiciels nécessaires à l’exploitation, aux logos, images,
            photos, graphiques, de quelle que nature qu’ils soient.
          </p>
          <p>
            La violation d’un de ces droits d’auteurs est un délit passible des sanctions
            mentionnées par l’article L.335-2 du Code de la Propriété intellectuelle.
          </p>
        </div>
      </IonContent>
    </IonModal>
  )
}

export default LegalModal
