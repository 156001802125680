const IconTypes = {
  DEFAULT: 'target',

  ACTIVITY: 'activity',
  AIRPLAY: 'airplay',
  ALERT_CIRCLE: 'alert-circle',
  ALERT_OCTAGON: 'alert-octagon',
  ALERT_TRIANGLE: 'alert-triangle',
  ALIGN_CENTER: 'align-center',
  ALIGN_JUSTIFY: 'align-justify',
  ALIGN_LEFT: 'align-left',
  ALIGN_RIGHT: 'align-right',
  ANCHOR: 'anchor',
  APERTURE: 'aperture',
  ARCHIVE: 'archive',
  ARROW_DOWN_CIRCLE: 'arrow-down-circle',
  ARROW_DOWN_LEFT: 'arrow-down-left',
  ARROW_DOWN_RIGHT: 'arrow-down-right',
  ARROW_DOWN: 'arrow-down',
  ARROW_LEFT_CIRCLE: 'arrow-left-circle',
  ARROW_LEFT: 'arrow-left',
  ARROW_RIGHT_CIRCLE: 'arrow-right-circle',
  ARROW_RIGHT: 'arrow-right',
  ARROW_UP_CIRCLE: 'arrow-up-circle',
  ARROW_UP_LEFT: 'arrow-up-left',
  ARROW_UP_RIGHT: 'arrow-up-right',
  ARROW_UP: 'arrow-up',
  AT_SIGN: 'at-sign',
  AWARD: 'award',
  BAR_CHART_2: 'bar-chart-2',
  BAR_CHART: 'bar-chart',
  BATTERY_CHARGING: 'battery-charging',
  BATTERY: 'battery',
  BELL_OFF: 'bell-off',
  BELL: 'bell',
  BLUETOOTH: 'bluetooth',
  BOLD: 'bold',
  BOOK_OPEN: 'book-open',
  BOOK: 'book',
  BOOKMARK: 'bookmark',
  BOX: 'box',
  BRIEFCASE: 'briefcase',
  CALENDAR: 'calendar',
  CAMERA_OFF: 'camera-off',
  CAMERA: 'camera',
  CAST: 'cast',
  CHECK_CIRCLE: 'check-circle',
  CHECK_SQUARE: 'check-square',
  CHECK: 'check',
  CHEVRON_DOWN: 'chevron-down',
  CHEVRON_LEFT: 'chevron-left',
  CHEVRON_RIGHT: 'chevron-right',
  CHEVRON_UP: 'chevron-up',
  CHEVRONS_DOWN: 'chevrons-down',
  CHEVRONS_LEFT: 'chevrons-left',
  CHEVRONS_RIGHT: 'chevrons-right',
  CHEVRONS_UP: 'chevrons-up',
  CHROME: 'chrome',
  CIRCLE: 'circle',
  CLIPBOARD: 'clipboard',
  CLOCK: 'clock',
  CLOUD_DRIZZLE: 'cloud-drizzle',
  CLOUD_LIGHTNING: 'cloud-lightning',
  CLOUD_RAIN: 'cloud-rain',
  CLOUD_SNOW: 'cloud-snow',
  CLOUD: 'cloud',
  CODE: 'code',
  CODEPEN: 'codepen',
  CODESANDBOX: 'codesandbox',
  COFFEE: 'coffee',
  COLUMNS: 'columns',
  COMMAND: 'command',
  COMPASS: 'compass',
  COPY: 'copy',
  CORNER_DOWN_LEFT: 'corner-down-left',
  CORNER_DOWN_RIGHT: 'corner-down-right',
  CORNER_LEFT_DOWN: 'corner-left-down',
  CORNER_LEFT_UP: 'corner-left-up',
  CORNER_RIGHT_DOWN: 'corner-right-down',
  CORNER_RIGHT_UP: 'corner-right-up',
  CORNER_UP_LEFT: 'corner-up-left',
  CORNER_UP_RIGHT: 'corner-up-right',
  CPU: 'cpu',
  CREDIT_CARD: 'credit-card',
  CROP: 'crop',
  CROSSHAIR: 'crosshair',
  DATABASE: 'database',
  DELETE: 'delete',
  DICE: 'dice',
  DISC: 'disc',
  DIVIDE_CIRCLE: 'divide-circle',
  DIVIDE_SQUARE: 'divide-square',
  DIVIDE: 'divide',
  DOLLAR_SIGN: 'dollar-sign',
  DOWNLOAD_CLOUD: 'download-cloud',
  DOWNLOAD: 'download',
  DRIBBBLE: 'dribbble',
  DROPLET: 'droplet',
  EDIT_2: 'edit-2',
  EDIT_3: 'edit-3',
  EDIT: 'edit',
  ENTERPRISE: 'enterprise',
  EXTERNAL_LINK: 'external-link',
  EYE_OFF: 'eye-off',
  EYE: 'eye',
  FACEBOOK: 'facebook',
  FAST_FORWARD: 'fast-forward',
  FEATHER: 'feather',
  FIGMA: 'figma',
  FILE_MINUS: 'file-minus',
  FILE_PLUS: 'file-plus',
  FILE_TEXT: 'file-text',
  FILE_TXT: 'file-txt',
  FILE_ZIP: 'file-zip',
  FILE_XLSX: 'file-xlsx',
  FILE_PNG: 'file-png',
  FILE_JPG: 'file-jpg',
  FILE_PDF: 'file-pdf',
  FILE_MP4: 'file-mp4',
  FILE_GIF: 'file-gif',
  FILE: 'file',
  FILM: 'film',
  FILTER: 'filter',
  FLAG: 'flag',
  FOLDER_MINUS: 'folder-minus',
  FOLDER_PLUS: 'folder-plus',
  FOLDER: 'folder',
  FRAMER: 'framer',
  FROWN: 'frown',
  GIFT: 'gift',
  GIT_BRANCH: 'git-branch',
  GIT_COMMIT: 'git-commit',
  GIT_MERGE: 'git-merge',
  GIT_PULL_REQUEST: 'git-pull-request',
  GITHUB: 'github',
  GITLAB: 'gitlab',
  GLOBE: 'globe',
  GRID: 'grid',
  HARD_DRIVE: 'hard-drive',
  HASH: 'hash',
  HEADPHONES: 'headphones',
  HEART: 'heart',
  HELP_CIRCLE: 'help-circle',
  HEXAGON: 'hexagon',
  HOME: 'home',
  IMAGE: 'image',
  INBOX: 'inbox',
  INFO: 'info',
  INSTAGRAM: 'instagram',
  ITALIC: 'italic',
  KEY: 'key',
  LAYERS: 'layers',
  LAYOUT: 'layout',
  LIFE_BUOY: 'life-buoy',
  LINK_2: 'link-2',
  LINK: 'link',
  LINKEDIN: 'linkedin',
  LIST: 'list',
  LIST_NUMBERS: 'list-numbers',
  LIST_BALLS: 'list_balls',
  LOADER: 'loader',
  LOCK: 'lock',
  LOG_IN: 'log-in',
  LOG_OUT: 'log-out',
  MAIL: 'mail',
  MAP_PIN: 'map-pin',
  MAP: 'map',
  MAXIMIZE_2: 'maximize-2',
  MAXIMIZE: 'maximize',
  MEH: 'meh',
  MENU: 'menu',
  MESSAGE_CIRCLE: 'message-circle',
  MESSAGE_SQUARE: 'message-square',
  MIC_OFF: 'mic-off',
  MIC: 'mic',
  MINIMIZE_2: 'minimize-2',
  MINIMIZE: 'minimize',
  MINUS_CIRCLE: 'minus-circle',
  MINUS_SQUARE: 'minus-square',
  MINUS: 'minus',
  MONITOR: 'monitor',
  MOON: 'moon',
  MORE_HORIZONTAL: 'more-horizontal',
  MORE_VERTICAL: 'more-vertical',
  MOUSE_POINTER: 'mouse-pointer',
  MOVE: 'move',
  MUSIC: 'music',
  NAVIGATION_2: 'navigation-2',
  NAVIGATION: 'navigation',
  OCTAGON: 'octagon',
  PACKAGE: 'package',
  PAPERCLIP: 'paperclip',
  PAUSE_CIRCLE: 'pause-circle',
  PAUSE: 'pause',
  PEN_TOOL: 'pen-tool',
  PERCENT: 'percent',
  PHONE_CALL: 'phone-call',
  PHONE_FORWARDED: 'phone-forwarded',
  PHONE_INCOMING: 'phone-incoming',
  PHONE_MISSED: 'phone-missed',
  PHONE_OFF: 'phone-off',
  PHONE_OUTGOING: 'phone-outgoing',
  PHONE: 'phone',
  PIE_CHART: 'pie-chart',
  PLAY_CIRCLE: 'play-circle',
  PLAY: 'play',
  PLUS_CIRCLE: 'plus-circle',
  PLUS_SQUARE: 'plus-square',
  PLUS: 'plus',
  POCKET: 'pocket',
  POWER: 'power',
  PRINTER: 'printer',
  RADIO: 'radio',
  REFRESH_CCW: 'refresh-ccw',
  REFRESH_CW: 'refresh-cw',
  REPEAT: 'repeat',
  RESOLUTION: 'resolution',
  RESOLUTION_LIST: 'resolution_list',
  REWIND: 'rewind',
  ROTATE_CCW: 'rotate-ccw',
  ROTATE_CW: 'rotate-cw',
  RSS: 'rss',
  SAVE: 'save',
  SCISSORS: 'scissors',
  SEARCH: 'search',
  SEND: 'send',
  SERVER: 'server',
  SETTINGS: 'settings',
  SHARE_2: 'share-2',
  SHARE: 'share',
  SHIELD_OFF: 'shield-off',
  SHIELD: 'shield',
  SHOPPING_BAG: 'shopping-bag',
  SHOPPING_CART: 'shopping-cart',
  SHUFFLE: 'shuffle',
  SIDEBAR: 'sidebar',
  SKIP_BACK: 'skip-back',
  SKIP_FORWARD: 'skip-forward',
  SLACK: 'slack',
  SLASH: 'slash',
  SLIDERS: 'sliders',
  SMALL_CIRCLE: 'small_circle',
  SMARTPHONE: 'smartphone',
  SMILE: 'smile',
  SPEAKER: 'speaker',
  SQUARE: 'square',
  STAR: 'star',
  STOP_CIRCLE: 'stop-circle',
  SUN: 'sun',
  SUNRISE: 'sunrise',
  SUNSET: 'sunset',
  TABLET: 'tablet',
  TAG: 'tag',
  TARGET: 'target',
  TERMINAL: 'terminal',
  THERMOMETER: 'thermometer',
  THUMBS_DOWN: 'thumbs-down',
  THUMBS_UP: 'thumbs-up',
  TOGGLE_LEFT: 'toggle-left',
  TOGGLE_RIGHT: 'toggle-right',
  TOOL: 'tool',
  TRASH_2: 'trash-2',
  TRASH: 'trash',
  TRELLO: 'trello',
  TRENDING_DOWN: 'trending-down',
  TRENDING_UP: 'trending-up',
  TRIANGLE: 'triangle',
  TRUCK: 'truck',
  TV: 'tv',
  TWITCH: 'twitch',
  TWITTER: 'twitter',
  TYPE: 'type',
  UMBRELLA: 'umbrella',
  UNDERLINE: 'underline',
  UNLOCK: 'unlock',
  UPLOAD_CLOUD: 'upload-cloud',
  UPLOAD: 'upload',
  USER_CHECK: 'user-check',
  USER_KEY: 'user-key',
  USER_MINUS: 'user-minus',
  USER_PLUS: 'user-plus',
  USER_X: 'user-x',
  USER: 'user',
  USERS: 'users',
  VALIDATE: 'validate',
  VIDEO_OFF: 'video-off',
  VIDEO: 'video',
  VOICEMAIL: 'voicemail',
  VOLUME_1: 'volume-1',
  VOLUME_2: 'volume-2',
  VOLUME_X: 'volume-x',
  VOLUME: 'volume',
  WATCH: 'watch',
  WIFI_OFF: 'wifi-off',
  WIFI: 'wifi',
  WIND: 'wind',
  X_CIRCLE: 'x-circle',
  X_OCTAGON: 'x-octagon',
  X_SQUARE: 'x-square',
  X: 'x',
  YOUTUBE: 'youtube',
  ZAP_OFF: 'zap-off',
  ZAP: 'zap',
  ZOOM_IN: 'zoom-in',
  ZOOM_OUT: 'zoom-out',
}

export default IconTypes
