import { createAsyncThunk } from '@reduxjs/toolkit'
import { getAxios, getTokenHeaders } from '../../utils/lib/requestAxios'
import jsonToString from '../../utils/lib/jsonToString'
import { setAppLoading, setErrorStatus } from '../app/redux'
import { downloadFile } from '../../utils/lib/downloadFile'

// Logs
export const requestUserLogs = createAsyncThunk<any, { userId?: string; filters?: any }>(
  'user/requestUserLogs',
  async ({ userId: impactedUserId = 'all', filters = {} }, { rejectWithValue }) => {
    const response: any = await getAxios(
      `/logs?${jsonToString(filters)}${
        impactedUserId === 'all' ? '' : `&impactedUserId=${impactedUserId}`
      }`,
      getTokenHeaders()
    )
    if (response.error) {
      return rejectWithValue(response.error.response.status)
    } else {
      return { userId: impactedUserId, data: response.data }
    }
  }
)

export const requestUserLogsStates = (builder) => {
  builder.addCase(requestUserLogs.fulfilled, (state, { payload: { userId, data } }) => {
    data.logs.map((element) => {
      if (element.initiatorInfo) {
        element.initiatorInfo = JSON.parse(element.initiatorInfo)
      }
      if (element.impactedUserInfo) {
        element.impactedUserInfo = JSON.parse(element.impactedUserInfo)
      }
    })
    state.userLogsById[userId] = data
  })
}

export const requestDownloadUserLogs = createAsyncThunk<any, any>(
  'user/requestDownloadUserLogs',
  async ({ userId: impactedUserId = 'all', filters = {} }, { dispatch, rejectWithValue }) => {
    dispatch(setAppLoading(true))
    const headers: any = {
      responseType: 'blob',
      ...getTokenHeaders(),
    }

    const response: any = await getAxios(
      `/logs/excel?${jsonToString(filters)}${
        impactedUserId === 'all' ? '' : `&impactedUserId=${impactedUserId}`
      }`,
      headers
    )
    if (response.error) {
      dispatch(setAppLoading(false))
      dispatch(setErrorStatus(response.error))
      return rejectWithValue(response.error.statusCode)
    }
    downloadFile(response)
    dispatch(setAppLoading(false))
  }
)

export const requestLogsInitiators = createAsyncThunk<any, undefined>(
  'user/requestLogsInitiators',
  async (_, { rejectWithValue }) => {
    const response: any = await getAxios('/logs/initiators', getTokenHeaders())
    if (response.error) {
      return rejectWithValue(response.error.response.status)
    } else {
      return { data: response.data }
    }
  }
)

export const requestLogsInitiatorsStates = (builder) => {
  builder.addCase(requestLogsInitiators.fulfilled, (state, { payload: { data } }) => {
    state.initiators = data
  })
}

export const requestLogsImpacted = createAsyncThunk<any, undefined>(
  'user/requestLogsImpacted',
  async (_, { rejectWithValue }) => {
    const response: any = await getAxios('/logs/impacted-users', getTokenHeaders())
    if (response.error) {
      return rejectWithValue(response.error.response.status)
    } else {
      return { data: response.data }
    }
  }
)

export const requestLogsImpactedStates = (builder) => {
  builder.addCase(requestLogsImpacted.fulfilled, (state, { payload: { data } }) => {
    state.impacted = data
  })
}
