export const PURPOSE_LIST = [
  'IDENTIFIER',
  'PASSWORD',
  'IDENTIFIER_AND_PASSWORD',
  'VOTE_RECEIPT',
  'PASSWORD_RESET',
  'SEAL_PASSWORD',

  // 'SEAL_PASSWORD_REMINDER',
  // 'UNLOCK_PASSWORD_REMINDER',
]
export const INIT_PURPOSE = 'IDENTIFIER'

export const PASSWORD_MODAL_PURPOSE_LIST = ['IDENTIFIER', 'PASSWORD', 'IDENTIFIER_AND_PASSWORD']

export const PURPOSE_ENDPOINTS = {
  IDENTIFIER: '/messages/send-identifiers',
  PASSWORD: '/messages/send-passwords',
  IDENTIFIER_AND_PASSWORD: '/messages/send-identifiers-and-passwords',
  VOTE_RECEIPT: '/messages/send-receipt-test',
  VOTE_REMINDER: '/messages/send-vote-reminders',
  SEAL_PASSWORD_REMINDER: '/messages/send-seal-password-reminders',
  UNLOCK_PASSWORD_REMINDER: '/messages/send-unlock-password-reminders',
}

export const ONE_USER_PURPOSE_ENDPOINTS = {
  IDENTIFIER: '/messages/send-one-identifier',
  PASSWORD: '/messages/send-one-password',
  IDENTIFIER_AND_PASSWORD: '/messages/send-one-identifier-and-password',
}

export const TABS = {
  email: {
    isInit: true,
  },
  sms: {
    disableFor: ['VOTE_RECEIPT'],
  },
  external: {
    disableFor: ['test'],
    disableVoteCheck: true,
  },
}

export const onlyTest = ['VOTE_RECEIPT']

export const onlyEditTemplate = ['PASSWORD_RESET', 'SEAL_PASSWORD']

export const CORRESPONDANCE = {
  EMAIL: 'email',
  EXTERNAL_SENDER: 'external',
  SMS: 'sms',
}

export const INV_CORRESPONDANCE = {
  email: 'EMAIL',
  external: 'EXTERNAL_SENDER',
  sms: 'SMS',
}
