import pages from './constants/lazyLoad'
import ROUTES from './constants/routes'

/*
 ** All the main Routes;
 ** Please use this when redirecting.
 ** Example
 **   ❌ push('/path');
 **   ❌ push(`path/${id}`);
 **   ❌ window.location.href = '/path'
 **   ✅ push(ROUTES.PATH);
 **   ✅ push(`${ROUTES.PATH}/${id}`);
 **   ✅ window.location.href = ROUTES.PATH
 */

// Type
interface ConfigType {
  path: string
  component: React.LazyExoticComponent<any>
  exact?: boolean
  noHeader?: boolean
  props?: { [key: string]: any }
  requiredPrivileges?: string[]
}

// Routes
const config: ConfigType[] = [
  // Common / Essential
  {
    path: ROUTES.HOME,
    exact: true,
    component: pages.Home,
  },
  {
    path: ROUTES.LOGIN,
    exact: true,
    component: pages.Login,
  },
  {
    path: ROUTES.RESET_PWD,
    exact: true,
    component: pages.ResetPassword,
  },

  // Configuration scrutin
  {
    path: ROUTES.CONFIG_POLL,
    exact: true,
    component: pages.PollConfiguration,
    requiredPrivileges: ['CONFIGURE_ELECTION'],
  },

  // Scrutins / Elections CSE
  {
    path: ROUTES.ELECTIONS,
    exact: true,
    component: pages.Scrutins,
    requiredPrivileges: [
      'CONFIGURE_ELECTION',
      'CONTROL_LISTS',
      'ACCESS_PARTICIPATION',
      'ACCESS_SIGNATURE',
    ],
  },
  {
    path: ROUTES.ESTABLISHMENTS,
    exact: true,
    component: pages.Establishments,
    requiredPrivileges: ['EDIT_ESTABLISHMENTS'],
  },
  {
    path: `${ROUTES.CREATE_UPDATE_ESTABLISHMENT}/:establishmentId`,
    exact: true,
    component: pages.EstablishmentEdition,
    requiredPrivileges: ['EDIT_ESTABLISHMENTS'],
  },
  {
    path: `${ROUTES.ELECTIONS}/résolutions/:id`,
    exact: true,
    component: pages.ResolutionParticipation,
    requiredPrivileges: ['ACCESS_PARTICIPATION'],
  },
  {
    path: `${ROUTES.SCRUTIN_EDITION}/:id`,
    exact: true,
    component: pages.ScrutinEdition,
    requiredPrivileges: ['CONFIGURE_ELECTION'],
  },
  {
    path: `${ROUTES.VOTE_SCRUTIN}/:scrutinId`,
    exact: true,
    component: pages.ScrutinLists,
    requiredPrivileges: ['VOTE'],
  },
  {
    path: `${ROUTES.VOTE_SCRUTIN}/:scrutinId/:listId`,
    exact: true,
    component: pages.VoteCSECandidates,
    requiredPrivileges: ['VOTE'],
  },
  {
    path: `${ROUTES.CONTROL_VOTE}/:scrutinType/:scrutinId`,
    exact: true,
    component: pages.PollControlVote,
    requiredPrivileges: ['CONTROL_LISTS'],
  },

  // User Creation / Edition
  {
    path: `${ROUTES.CREATE_UPDATE_USER}`,
    exact: false,
    component: pages.UserEdition,
    requiredPrivileges: ['EDIT_USER_INFORMATION'],
  },
  {
    path: `${ROUTES.CREATE_UPDATE_USER}/:userId`,
    exact: true,
    component: pages.UserEdition,
    requiredPrivileges: ['EDIT_USER_INFORMATION', 'CONSULT_USER_PROFILE'],
  },
  {
    path: `${ROUTES.EDIT_ROLE}`,
    exact: true,
    component: pages.UserRoleEdition,
    requiredPrivileges: ['EDIT_ROLE'],
  },

  // Users
  {
    path: ROUTES.USERS_LIST,
    exact: true,
    component: pages.Users,
    requiredPrivileges: [
      'CONSULT_USER_PROFILE',
      'CONSULT_USER_VOTING_LOG',
      'EDIT_USER_INFORMATION',
      'EDIT_USER_PRIVILEGES',
      'MANAGE_PASSWORD_RECOVERY',
    ],
  },
  {
    path: `${ROUTES.VOTING_LOGS}`,
    exact: true,
    component: pages.VotingLogs,
    requiredPrivileges: ['CONSULT_USER_VOTING_LOG'],
  },
  {
    path: `${ROUTES.VOTING_LOGS}/:id`,
    exact: true,
    component: pages.VotingLogs,
    requiredPrivileges: ['CONSULT_USER_VOTING_LOG'],
  },

  // Lists
  {
    path: `${ROUTES.POLL}/:id`,
    exact: true,
    component: pages.AdminPoll,
    requiredPrivileges: ['CONFIGURE_ELECTION'],
  },

  // Scellement
  {
    path: ROUTES.SEALING,
    exact: true,
    component: pages.SealingPage,
    requiredPrivileges: ['LOCK_URN'],
  },
  {
    path: ROUTES.CELL_MEMBERS,
    exact: true,
    component: pages.CellMembers,
    requiredPrivileges: ['MANAGE_CELL_MEMBERS'],
  },

  // Participation signature
  {
    path: `${ROUTES.PARTICIPATION_SIGNATURE}/:id`,
    exact: true,
    component: pages.ParticipationSignaturePage,
    requiredPrivileges: ['ACCESS_PARTICIPATION', 'ACCESS_SIGNATURE'],
  },
  {
    path: `${ROUTES.PARTICIPATION_SIGNATURE}/:type/:id`,
    exact: true,
    component: pages.ParticipationSignaturePage,
    requiredPrivileges: ['ACCESS_PARTICIPATION', 'ACCESS_SIGNATURE'],
  },

  // Codes
  {
    path: `${ROUTES.PREVIEW_CODES}`,
    exact: true,
    component: pages.SendCodes,
    requiredPrivileges: ['MASS_CODE_SEND'],
  },
  {
    path: `${ROUTES.SEND_CODES}`,
    exact: true,
    component: pages.SendCodesSender,
    requiredPrivileges: ['MASS_CODE_SEND'],
  },
  {
    path: `${ROUTES.SEND_CODES}/:mode`,
    exact: true,
    component: pages.SendCodesSender,
    requiredPrivileges: ['MASS_CODE_SEND'],
  },

  // System
  {
    path: ROUTES.SYSTEM_STATUS,
    exact: true,
    component: pages.SystemStatus,
  },
  {
    path: ROUTES.VERIFICATION,
    exact: true,
    component: pages.Verification,
  },
  {
    path: ROUTES.COLOR_THEME,
    exact: true,
    component: pages.ColorTheme,
    requiredPrivileges: ['CONFIGURE_ELECTION'],
  },
  {
    path: `${ROUTES.BALLOT_RECEIPT}`,
    exact: true,
    component: pages.BallotReceipt,
    requiredPrivileges: ['VOTE'],
  },

  // Demo
  {
    path: ROUTES.DEMO_LOGIN,
    exact: true,
    component: pages.DemoLogin,
  },

  // Animateur
  {
    path: `${ROUTES.ANIMATE_VOTE}/:id`,
    exact: true,
    component: pages.AnimateVote,
    requiredPrivileges: ['ANIMATE_VOTE'],
  },
  {
    path: `${ROUTES.ANIMATE_VOTE}/:id/résultats`,
    exact: true,
    component: pages.AnimateVoteResults,
    requiredPrivileges: ['ANIMATE_VOTE'],
  },

  /****       NON CSE     ****/
  // Scrutins Uninominaux
  {
    path: `${ROUTES.ELECTIONS}/:id`,
    exact: true,
    component: pages.CandidatesElection,
  },
  {
    path: `${ROUTES.VOTE_ELECTION}/:id`,
    exact: true,
    component: pages.VoteElections,
  },

  // Resolutions
  {
    path: `${ROUTES.RESOLUTIONS_ADMIN}/:id`,
    exact: true,
    component: pages.AdminResolution,
  },
  {
    path: `${ROUTES.RESOLUTIONS}/:id`,
    exact: true,
    component: pages.ResolutionVote,
  },
  {
    path: `${ROUTES.RESOLUTIONS}/:id/reçu-de-vote`,
    exact: true,
    component: pages.ResolutionVoteBallot,
  },

  // Lists
  {
    path: `${ROUTES.ADMIN_LIST}/:id`,
    exact: true,
    component: pages.AdminList,
  },
  {
    path: `${ROUTES.VOTE_LIST}/:id`,
    exact: true,
    component: pages.ListsPreview,
  },
  {
    path: `${ROUTES.VOTE_LIST}/:id/:listId`,
    exact: true,
    component: pages.VoteCandidateList,
  },

  // Vote Management
  {
    path: `${ROUTES.VOTES_RIGHTS}/:id`,
    exact: true,
    component: pages.VotesRightsManagement,
    requiredPrivileges: ['CONFIGURE_ELECTION'],
  },

  // Help Files Management
  {
    path: ROUTES.HELP_FILES_MANAGEMENT,
    exact: true,
    component: pages.HelpFilesManagement,
    requiredPrivileges: ['HELP_FILES_MANAGEMENT'],
  },

  // Procès-Verbaux
  {
    path: ROUTES.PV_LIST,
    exact: true,
    component: pages.RecordList,
    requiredPrivileges: ['ACCESS_MINUTES'],
  },
  {
    path: `${ROUTES.PV_LIST}/:type/:id`,
    exact: true,
    component: pages.RecordEdit,
    requiredPrivileges: ['ACCESS_MINUTES'],
  },

  // Login and password Management
  {
    path: ROUTES.FORMAT_MANAGEMENT,
    exact: true,
    component: pages.FormatManagement,
    requiredPrivileges: ['CODE_FORMAT_MANAGEMENT'],
  },
  {
    path: ROUTES.PGP_KEYS_MANAGEMENT,
    exact: true,
    component: pages.PgpKeysManagement,
    requiredPrivileges: ['PGP_KEYS_MANAGEMENT'],
  },
  {
    path: ROUTES.FOLLOW_PASSWORDS,
    exact: true,
    component: pages.FollowPasswords,
    requiredPrivileges: ['MANAGE_PASSWORD_RECOVERY'],
  },
  {
    path: ROUTES.MAINTENANCE,
    exact: true,
    component: pages.Maintenance,
    requiredPrivileges: ['PGP_KEYS_MANAGEMENT'],
  },
]

export const hideHeader = [ROUTES.LOGIN, ROUTES.DEMO_LOGIN, ROUTES.RESET_PWD]
export const hideAssistanceFooter = [ROUTES.LOGIN, ROUTES.DEMO_LOGIN, ROUTES.RESET_PWD]

export default config
