import { createAsyncThunk } from '@reduxjs/toolkit'
import { toDisplayDate } from '../../utils/date-time.utils'
import { downloadFile } from '../../utils/lib/downloadFile'
import jsonToString from '../../utils/lib/jsonToString'
import { getAxios, getTokenHeaders, setAxios, deleteAxios } from '../../utils/lib/requestAxios'
import sendToHome from '../../utils/lib/sendToHome'
import { setAppLoading, setErrorStatus } from '../app/redux'

//Export Files
export const requestDownloadElectionDatas = createAsyncThunk<any, any>(
  'requestDownloadElectionDatas',
  async (type: string, { rejectWithValue, dispatch }) => {
    dispatch(setAppLoading(true))
    const headers: any = {
      responseType: 'arraybuffer',
      ...getTokenHeaders(),
    }

    const PATHS = {
      CSE: '/cse/exports/donnees-elections',
      list: '/lis/exports/donnees-elections',
      lis: '/lis/exports/donnees-elections',
      res: '/res/exports/donnees-elections',
      uni: '/uni/exports/donnees-elections',
    }

    const response: any = await getAxios(PATHS[type], headers)

    dispatch(setAppLoading(false))
    if (response.error) {
      dispatch(setErrorStatus(response.error))
      return rejectWithValue(response.error.statusCode)
    }
    downloadFile(response)
  }
)

// Import Files
export const requestImport = createAsyncThunk<any, any>(
  'auth/requestImport',
  async ({ files, onUploadProgress, is2ndRound }, { rejectWithValue, fulfillWithValue }) => {
    let headers: any = getTokenHeaders()

    const bodyFormData = new FormData()
    bodyFormData.append('zip', files.zipFile)
    bodyFormData.append('excel', files.excelFile)

    headers.headers = {
      ...headers.headers,
      'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>',
      'Access-Control-Allow-Origin': '*',
    }
    headers.onUploadProgress = onUploadProgress

    const response: any = await setAxios(
      is2ndRound ? '/imports/upload-second-round' : '/imports/upload',
      bodyFormData,
      headers
    )

    if (response.error) {
      if (response.error?.response?.data?.message) {
        return { error: response.error?.response?.data?.message }
      } else {
        return rejectWithValue(response.error.response.status)
      }
    }
    return response.data
  }
)

export const requestImportStates = (builder) => {
  builder.addCase(requestImport.pending, (state) => {
    state.error = undefined
    state.status = true
    state.success = false
    state.percentage = 0
  })
}

// Action asynchrone pour gérer la fin de l'importation
export const requestImportFinished = createAsyncThunk(
  'auth/requestImportFinished',
  async (data: { response: any; success: boolean }, { dispatch }) => {
    dispatch(setAppLoading(true))

    try {
      return { data }
    } finally {
      dispatch(setAppLoading(false))
    }
  }
)

export const requestImportStatesFinished = (builder) => {
  builder.addCase(requestImportFinished.pending, (state) => {
    state.error = undefined
    state.status = true
    state.success = false
    state.percentage = 0
  })

  builder.addCase(requestImportFinished.fulfilled, (state, { payload }) => {
    const response = payload.data.response
    const success = payload.data.success

    if (response.error) {
      state.error = response.error
      state.status = false
      state.success = false
      state.percentage = 0
    } else if (success) {
      state.error = false
      state.success = success
      state.percentage = 100
    }
  })

  builder.addCase(requestImportFinished.rejected, (state, payload) => {})
}

export const requestDownload2ndRound = createAsyncThunk<any, undefined>(
  'files/download2ndRound',
  async (_, { rejectWithValue, dispatch }) => {
    dispatch(setAppLoading(true))
    const headers: any = {
      responseType: 'blob',
      ...getTokenHeaders(),
    }
    const response: any = await getAxios('/cse/exports/polls-with-second-round', headers)

    if (response.error) {
      dispatch(setAppLoading(false))
      dispatch(setErrorStatus(response.error))
      return rejectWithValue(response.error.statusCode)
    }
    downloadFile(response, `Scrutins second tour ${toDisplayDate(new Date())}.xlsx`)
    dispatch(setAppLoading(false))
  }
)

// Export Users File
export const requestExport = createAsyncThunk<any, any>(
  'files/requestExport',
  async ({ content = '', filters = {} }, { rejectWithValue }) => {
    const response: any = await getAxios(`/exports/${content}?${jsonToString(filters)}`, {
      ...getTokenHeaders(),
      responseType: 'blob',
    })
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    const fileName = response.headers['content-disposition']?.match(/filename="(.+)"/)?.[1]
    link.href = url
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
    if (response.error) {
      return rejectWithValue(response.error.statusCode)
    }
    return null
  }
)

export const requestExportStates = (builder) => {
  builder.addCase(requestExport.pending, (state) => {
    state.error = undefined
    state.status = true
    state.success = false
  })

  builder.addCase(requestExport.fulfilled, (state, { payload }) => {
    state.error = undefined
    state.status = false
    state.success = true
  })

  builder.addCase(requestExport.rejected, (state, payload) => {
    console.info(payload)
    state.error = 'Error'
    state.status = false
    state.success = false
  })
}

// Delete Datas & Refresh
export const requestClearDatas = createAsyncThunk<any, undefined>(
  'files/requestClearDatas',
  async (_, { dispatch }) => {
    dispatch(setAppLoading(true))
    await deleteAxios('/reset', getTokenHeaders())
    sendToHome()
    dispatch(setAppLoading(false))
  }
)

export const requestDownloadErrors = createAsyncThunk<any, undefined>(
  'files/requestDownloadErrors',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setAppLoading(true))
    const headers: any = {
      responseType: 'blob',
      ...getTokenHeaders(),
    }
    const response: any = await getAxios('/import-errors', headers)
    if (response.error) {
      dispatch(setAppLoading(false))
      dispatch(setErrorStatus(response.error))
      return rejectWithValue(response.error.statusCode)
    }
    downloadFile(response)
    dispatch(setAppLoading(false))
  }
)
