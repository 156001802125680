import { md, pki, util } from 'node-forge'

export class BallotContent {
  listId: number | string | null
  crossedOutCandidateIds: number[]
  voteSector: string

  constructor(stringContent: string) {
    const content = JSON.parse(stringContent)
    this.listId = content.listId
    this.crossedOutCandidateIds = content.crossedOutCandidateIds
    this.voteSector = content.voteSector
  }
}

export const encryptBallot = async (
  ballotContent: BallotContent,
  publicKey: string
): Promise<string> => {
  return encryptData(JSON.stringify(ballotContent), publicKey)
}

export const encryptAnyBallot = async (ballotContent: any, publicKey: string): Promise<string> => {
  return encryptData(JSON.stringify(ballotContent), publicKey)
}

/**
 * generates a unique print.
 */
export const generateBallotPrint = async (): Promise<string> => {
  return crypto.randomUUID()
}
// on the client side, the ballots are encrypted by the frontend with the public key at the time of voting
export const encryptData = async (value: string, key: string): Promise<string> => {
  const publicKey = pki.publicKeyFromPem(key)
  return util.encode64(
    publicKey.encrypt(value, 'RSA-OAEP', {
      md: md.sha256.create(),
    })
  )
}
