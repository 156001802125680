// Hooks
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import useLocalize from '../../../../locales/hook'
import { useRandomRole, useUsersByRole } from '../../../../redux/demo/hooks'

// Components
import UserItem from './UserItem'
import Icon from '../../../../atoms/Icon/Icon'

// Types
import IconTypes from '../../../../utils/types/IconTypes'

const RoleItem: React.FC<{
  role: string
  selection: any
  filters: any
  members: any
  currentUserId: string
  onUsersAdd: (role: string, userId: string) => void
  onUserSelect: (userId: string) => void
}> = ({ role, selection = [], currentUserId, filters, members, onUsersAdd, onUserSelect }) => {
  // Hooks
  const t = useLocalize()
  const getRandomRole = useRandomRole(role)
  const { users, getUsersByRoles } = useUsersByRole(role)

  // Refs
  const timer = useRef<NodeJS.Timeout | null>(null)
  const inputRef = useRef<HTMLInputElement | null>(null)

  // States
  const [search, setSearch] = useState<string>('')
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [status, setStatus] = useState<boolean>(false)

  // Memos
  const usersToShow = useMemo(() => {
    return users.filter((userId) => !selection.includes(userId))
  }, [users, selection])

  // Handlers
  const handleAdd = useCallback(() => {
    setSearch('')
    setIsOpen((prev) => !prev)
  }, [])
  const handleTextChange = useCallback(
    (ev) => {
      setSearch(ev.target.value || '')
      if (timer.current) clearTimeout(timer.current)
      timer.current = setTimeout(() => {
        getUsersByRoles(ev.target.value, filters)
      }, 800)
    },
    [getUsersByRoles, filters]
  )

  const handleDpClick = useCallback(
    (ev) => {
      setStatus(!status)
      ev.preventDefault()
    },
    [setStatus, status]
  )

  const handleRandom = useCallback(() => {
    setStatus(false)
    getRandomRole({
      excludedUserIds: selection,
      establishmentId: filters?.establishmentId,
      collegeId: filters?.collegeId,
    })
  }, [selection, filters, getRandomRole])

  const handleFocus = useCallback(() => {
    if (timer.current) clearTimeout(timer.current)
    setStatus(true)
  }, [])

  const handleUserClick = useCallback(
    (userId: string) => {
      setIsOpen(false)
      onUsersAdd(role, userId)
    },
    [onUsersAdd, role]
  )

  const handleUserSelect = useCallback(
    (userId) => {
      onUserSelect(userId)
    },
    [onUserSelect]
  )

  const handleBlur = useCallback(() => setStatus(false), [])

  // Effect
  useEffect(() => {
    getUsersByRoles(search, {
      establishmentIds: [filters.establishmentId],
      collegeTypes: [filters.collegeType],
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, filters])

  return (
    <div className='roleItem'>
      <div className='head'>
        <div className='label'>{t(`role_${role.toLowerCase()}`)}</div>
        <div className='add' onClick={handleAdd}>
          <Icon icon={isOpen ? IconTypes.MINUS : IconTypes.PLUS} />
          <div className='label'>{isOpen ? t('close') : t('add')}</div>
        </div>
      </div>
      {isOpen && (
        <div className='roleBox'>
          <Icon
            icon={IconTypes.DICE}
            className='random'
            onClick={handleRandom}
            disabled={selection?.length >= 15}
          />
          <div className='dropdownBox'>
            <div className={`inputBox ${status ? 'open' : ''}`}>
              {!selection?.length && (
                <div className='searchIcon'>
                  <Icon className='search' icon={IconTypes.SEARCH} />
                </div>
              )}
              {!!selection?.length && <div className='search' />}
              <div className='selectionInput'>
                <input
                  ref={inputRef}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  onChange={handleTextChange}
                  placeholder={t('search')}
                />
              </div>
              <div className='dropdownIcon'>
                <Icon
                  onMouseDown={handleDpClick}
                  onPointerDown={handleDpClick}
                  icon={status ? IconTypes.CHEVRON_UP : IconTypes.CHEVRON_DOWN}
                />
              </div>
            </div>
            <div className={`dropdown ${status ? '' : 'hidden'}`}>
              {usersToShow.map((userId) => (
                <UserItem userId={userId} key={userId} small onClick={handleUserClick} />
              ))}
              {!usersToShow.length && <div className='no_data'>{t('no_users')}</div>}
            </div>
          </div>
        </div>
      )}
      <div className='box'>
        {selection?.map((userId) => (
          <UserItem
            userId={userId}
            key={userId}
            selected={userId === currentUserId}
            onClick={handleUserSelect}
            onDelete={handleUserClick}
          />
        ))}
        {!selection?.length && <div className='nothing'>{t('demo_nothing_selected')}</div>}
      </div>
    </div>
  )
}

export default RoleItem
