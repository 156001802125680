import { useCallback } from 'react'
import Icon from '../../../../atoms/Icon/Icon'
import UserDefaultPicture from '../../../../components/UserDefaultPicture/UserDefaultPicture'
import { useUser } from '../../../../redux/user/hooks'
import getFullName from '../../../../utils/lib/getFullName'
import IconTypes from '../../../../utils/types/IconTypes'

const UserItem: React.FC<{
  userId: string
  small?: boolean
  selected?: boolean
  noDelete?: boolean
  lastNameFirst?: boolean
  onClick?: (userId: string, user?: any) => void
  onDelete?: (userId: string, user?: any) => void
}> = ({ userId, small, noDelete = false, lastNameFirst = false, selected, onClick, onDelete }) => {
  const { user } = useUser(userId)

  const handleClick = useCallback(
    (ev) => {
      onClick?.(userId, user)
      ev.preventDefault()
      ev.stopPropagation()
    },
    [onClick, user, userId]
  )

  const handleDelete = useCallback(
    (ev) => {
      onDelete?.(userId, user)
      ev.preventDefault()
      ev.stopPropagation()
    },
    [onDelete, user, userId]
  )

  if (small)
    return (
      <div className={`userItem ${selected}`} onMouseDown={handleClick} onPointerDown={handleClick}>
        <div className='label'>{getFullName(user, lastNameFirst)}</div>
        {!noDelete && <Icon icon={IconTypes.MINUS} onMouseDown={handleDelete} />}
      </div>
    )
  return (
    <div className={`userItem ${selected}`} onMouseDown={handleClick} onPointerDown={handleClick}>
      <UserDefaultPicture userId={userId} />
      <div className='label'>{getFullName(user, lastNameFirst)}</div>
      {!noDelete && <Icon icon={IconTypes.MINUS} onPointerDown={handleDelete} />}
    </div>
  )
}

export default UserItem
