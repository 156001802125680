import jwtDecode from 'jwt-decode'
import { AuthState } from './redux'
import { getCookie } from '../../utils/security/cookies'
import { getSessionStorage } from '../../utils/security/localStorage'

export const tokenKey = 'VE9LRU4gVEJE'
export const tokenRefreshKey = 'GF7PAU5hDQKL'
export const roleKey = 'Uk9MRSBUQkQ'

const defaultInfo: AuthState = {
  token: null,
  exp: null,
  needsPersonal: false,
  needMFAValidation: false,
  mfaValidationInfo: null,
  needMFACode: false,
  closeVotingProcess: false,
}

export const getToken = (forceCookie?: boolean) =>
  forceCookie ? getCookie(tokenKey) : getSessionStorage(tokenKey) || getCookie(tokenKey)
export const getRefreshToken = (forceCookie?: boolean) =>
  forceCookie
    ? getCookie(tokenRefreshKey)
    : getSessionStorage(tokenRefreshKey) || getCookie(tokenRefreshKey)
export const getRole = (forceCookie?: boolean) =>
  forceCookie
    ? getCookie(roleKey)?.replace('ROLE_', '')
    : getSessionStorage(roleKey)?.replace('ROLE_', '') || getCookie(roleKey)?.replace('ROLE_', '')

export const getTokenInfo = (token?: string, role?: number): AuthState | any => {
  let tk = token || getToken()
  const rl = role || getRole() || undefined
  if (tk) {
    try {
      const info: any | undefined = jwtDecode(tk)
      return {
        token: tk,
        ...(info || {}),
        role: rl || info.role,
      }
    } catch (err) {
      console.error('Error retrieving token info', tk)
    }
  }
  return defaultInfo
}
