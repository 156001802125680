import { createAsyncThunk } from '@reduxjs/toolkit'
import { getAxios, getTokenHeaders } from '../../utils/lib/requestAxios'

//Request Server time
export const requestServerTime = createAsyncThunk<any, undefined>(
  'time/requestServerTime',
  async (_, { rejectWithValue }) => {
    const response: any = await getAxios('/time/now', getTokenHeaders())
    if (response.error) {
      return rejectWithValue(response.error.response.status)
    } else {
      return response.data
    }
  }
)

export const requestServerTimeState = (builder) => {
  builder.addCase(requestServerTime.fulfilled, (state, { payload }) => {
    state.serverTime = {
      currentTime: payload.currentTime
        ? Math.floor(Number(Date.parse(payload.currentTime)) / 1000) * 1000
        : Math.floor(Date.now() / 1000) * 1000,
    }
    state.localTime = { currentTime: Math.floor(Date.now() / 1000) * 1000 }
  })
}
