// Hooks
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import useLocalize from '../../../locales/hook'
import { useNowTimer } from '../../../redux/time/hooks'
import { useAssistanceInfos, useUserId } from '../../../redux/profile/hooks'

// Components
import { IonFooter, useIonRouter } from '@ionic/react'

// Style & Utils
import './AssistanceFooter.scss'
import { hideAssistanceFooter } from '../..'
import { DEFAULT_TIMEZONE, toStringDate } from '../../../utils/date-time.utils'
import { useAppDispatch } from '../../../init/store'
import { requestServerTime } from '../../../redux/time/services'
import { updateServerTime } from '../../../redux/time/redux'
import { useEstablishments } from '../../../redux/establishments/hooks'
import { useUser } from '../../../redux/user/hooks'
import LegalModal from '../../../components/LegalModal/LegalModal'
import Tooltip from '../../../atoms/Tooltip/Tooltip'
import * as moment from 'moment-timezone'

const getServerTimeDelay = 60000

// TODO: use real datas
const AssistanceFooter: React.FC = () => {
  const dispatch = useAppDispatch()

  const [isModalOpen, setIsModalOpen] = useState(false)

  const userId = useUserId()
  const { user } = useUser(userId)
  const { establishmentsById } = useEstablishments()

  const distinctTimezone = useMemo(() => {
    const timezoneList: string[] = []

    if (establishmentsById?.length) {
      if (user && user.establishmentId && establishmentsById[user.establishmentId]) {
        timezoneList.push(establishmentsById[user.establishmentId].timezone)
      } else {
        establishmentsById.forEach((elem) => {
          if (elem?.timezone) {
            timezoneList.push(elem.timezone)
          }
        })
      }
    }

    if (!timezoneList.length) {
      timezoneList.push(DEFAULT_TIMEZONE)
    }
    return timezoneList
  }, [establishmentsById, user])

  const t = useLocalize()

  const { today } = useNowTimer()
  const { routeInfo } = useIonRouter()
  const { supportEmail, supportPhone } = useAssistanceInfos()

  const TODAY = useMemo(() => new Date(today), [today])
  let timer = useRef<NodeJS.Timer | null>(null)
  let mounted = useRef<boolean>(false)

  const actualTimes = useMemo(() => {
    const timesList: string[] = []
    distinctTimezone
      .slice()
      .sort((a, b) => moment.tz(a).utcOffset() - moment.tz(b).utcOffset())
      .map((timezone) => {
        timesList.push(toStringDate(TODAY, 'DD/MM/YYYY - HH[h]mm', timezone, true))
      })
    return timesList
  }, [distinctTimezone, TODAY])

  const sealInfo = useMemo(() => {
    if (supportEmail && supportPhone) {
      return t('sealing_assistance_edit', {
        supportEmail,
        supportPhone,
      })
    }
    if (supportEmail || supportPhone) {
      return t('sealing_assistance_edit_one', {
        support: supportEmail || supportPhone,
      })
    }
    return ''
  }, [supportEmail, supportPhone, t])

  const getServerTime = useCallback(() => {
    if (mounted.current) {
      //On recupere l'heure du server
      dispatch(requestServerTime())

      //Apres 2s (pour laisse le temps au serveur de repondre), on va calculer toutes les secondes
      //l'ajustement de l'heure de la plateforme jusqu'au prochain refresh avec l'heure du serveur
      let updateInterval = null
      if (updateInterval) clearInterval(updateInterval)
      setTimeout(() => {
        let updateInterval
        if (mounted.current) {
          let timerSetInterval = getServerTimeDelay - 3000
          updateInterval = setInterval(() => {
            dispatch(updateServerTime())
          }, 1000)
          setTimeout(() => {
            clearInterval(updateInterval)
          }, timerSetInterval)
        } else {
          if (updateInterval) clearInterval(updateInterval)
        }
      }, 2000)
    }
  }, [dispatch])

  useEffect(() => {
    mounted.current = true
    if (timer.current) clearInterval(timer.current)
    getServerTime()
    timer.current = setInterval(() => {
      getServerTime()
    }, getServerTimeDelay)

    return () => {
      mounted.current = false
      if (timer.current) clearInterval(timer.current)
    }
  }, [getServerTime])

  if (hideAssistanceFooter.includes(routeInfo.pathname)) {
    return null
  }

  const uniqueTimezones = [...new Set(distinctTimezone)]

  return (
    <>
      <IonFooter className='AssistanceFooter'>
        <div>{sealInfo}</div>
        <div className='pointer' onClick={() => setIsModalOpen(true)}>
          {t('legal_mentions')}
        </div>
        <div className='serverDateContainer'>
          {uniqueTimezones.length <= 1 &&
            uniqueTimezones.map((timezone) => (
              <div className='datetime' key={timezone}>
                {toStringDate(TODAY, 'DD/MM/YYYY - HH[h]mm', timezone, true)}
              </div>
            ))}
          {uniqueTimezones.length > 1 && (
            <Tooltip label={actualTimes} horizontal='center' className='column pointer'>
              <div className='datetime' key={DEFAULT_TIMEZONE}>
                {toStringDate(TODAY, 'DD/MM/YYYY - HH[h]mm', DEFAULT_TIMEZONE, true)}
              </div>
              <div className='datetime'>
                {t('timezone_display_more', {
                  count: uniqueTimezones.filter((timezone) => timezone !== DEFAULT_TIMEZONE).length,
                })}
              </div>
            </Tooltip>
          )}
        </div>
      </IonFooter>

      <LegalModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} t={t} />
    </>
  )
}

export default AssistanceFooter
