import { createSlice } from '@reduxjs/toolkit'

// Services
import {
  requestSetProdState,
  requestImportStatusState,
  requestEstablishmentsDataState,
  requestModesState,
  requestMaintenanceState,
  requestStatusState,
  requestPlatformHashesState,
  requestSetConfigState,
  requestNewHashesState,
  requestSetStatusState,
  requestSetDownload1RoundState,
} from './services'

// Define a type for the slice state
export type AppConfigState = {
  isProd: boolean
  isDemo: boolean
  maintenance: string
  thirdPartyUsed: boolean
  establishments: Array<any>
  loaders: any
  collegeIds: any
  formattedEstablishments: Array<any>
  requested?: any
  status?: any
  hashes?: any
}

// Define the initial state using that type
const initialState: AppConfigState = {
  isProd: false,
  isDemo: false,
  maintenance: 'disabled',
  thirdPartyUsed: false,
  establishments: [],
  loaders: {},
  collegeIds: undefined,
  formattedEstablishments: [],
  requested: {},
  status: {},
  hashes: {},
}

export const appConfig = createSlice({
  name: 'appConfig',
  initialState,
  reducers: {
    setDownload1RoundStatus(state) {
      if (state.status) {
        state.status.DOWNLOAD_1_ROUND = true
      }
    },
    clearDownload1RoundStatus(state) {
      if (state.status) {
        state.status.DOWNLOAD_1_ROUND = false
      }
    },
  },
  extraReducers: (builder) => {
    requestModesState(builder)
    requestStatusState(builder)
    requestSetProdState(builder)
    requestNewHashesState(builder)
    requestSetConfigState(builder)
    requestImportStatusState(builder)
    requestPlatformHashesState(builder)
    requestEstablishmentsDataState(builder)
    requestSetStatusState(builder)
    requestMaintenanceState(builder)
    requestSetDownload1RoundState(builder) // Ajout de l'état
  },
})

export const { setDownload1RoundStatus, clearDownload1RoundStatus } = appConfig.actions

export default appConfig.reducer
