import { createAsyncThunk } from '@reduxjs/toolkit'
import { downloadFile } from '../../utils/lib/downloadFile'
import jsonToString from '../../utils/lib/jsonToString'
import { getAxios, getTokenHeaders } from '../../utils/lib/requestAxios'
import { setAppLoading, setErrorStatus } from '../app/redux'

// Request signatures
export const requestSignatures = createAsyncThunk<
  unknown,
  {
    filters: {
      pollId: string | number
      collegeId: string | number
      skip: number
      take: number
      onlyVoters: boolean
    }
  }
>('signatures/requestSignatures', async ({ filters = {} }, { rejectWithValue }) => {
  const response: any = await getAxios(`/signatures?${jsonToString(filters)}`, getTokenHeaders())

  if (response.error) {
    return rejectWithValue(response.error.response.status)
  } else {
    return {
      pollId: filters.pollId,
      collegeId: filters.collegeId,
      data: response.data,
    }
  }
})

export const requestSignaturesStates = (builder) => {
  builder.addCase(requestSignatures.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(requestSignatures.fulfilled, (state, { payload }) => {
    state.isLoading = false
    state.totalUsers[payload.collegeId || payload.pollId] = payload.data.userCount
    state.signatures[payload.collegeId || payload.pollId] = payload.data.usersWithSignatures
  })

  builder.addCase(requestSignatures.rejected, (state) => {
    state.isLoading = false
  })
}

const pathTypes = {
  cse: '/cse/polls/',
  res: '/res/polls/',
  uni: '/uni/polls/',
  list: '/lis/polls/',
  lis: '/lis/polls/',
}

export const requestParticipation = createAsyncThunk<
  unknown,
  {
    pollId
    type
  }
>('signatures/requestParticipation', async ({ pollId, type }, { rejectWithValue }) => {
  const response: any = await getAxios(
    `${pathTypes[type]}${pollId}/participation?`,
    getTokenHeaders()
  )

  if (response.error) {
    return rejectWithValue(response.error.response.status)
  } else {
    return {
      pollId,
      data: response.data,
    }
  }
})

export const requestParticipationStates = (builder) => {
  builder.addCase(requestParticipation.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(requestParticipation.fulfilled, (state, { payload }) => {
    state.isLoading = false
    state.participation[payload.pollId] = payload.data
  })

  builder.addCase(requestParticipation.rejected, (state) => {
    state.isLoading = false
  })
}

export const requestDownloadParticipation = createAsyncThunk<
  unknown,
  {
    pollId
    type
    fileType
  }
>(
  'signatures/requestDownloadParticipation',
  async ({ pollId, type, fileType }, { rejectWithValue, dispatch }) => {
    dispatch(setAppLoading(true))
    const headers: any = {
      responseType: 'blob',
      ...getTokenHeaders(),
    }

    const response: any = await getAxios(
      `/${type}/exports/participation/${fileType}?pollId=${pollId}`,
      headers
    )

    dispatch(setAppLoading(false))
    if (response.error) {
      dispatch(setErrorStatus(response.error))
      return rejectWithValue(response.error.statusCode)
    }
    downloadFile(response)
  }
)

export const requestDownloadSignatures = createAsyncThunk<
  unknown,
  {
    pollId
    type
    fileType
    onlyVoters
  }
>(
  'signatures/requestDownloadSignatures',
  async ({ pollId, fileType, onlyVoters }, { rejectWithValue, dispatch }) => {
    dispatch(setAppLoading(true))
    const headers: any = {
      responseType: 'blob',
      ...getTokenHeaders(),
    }

    const response: any = await getAxios(
      `/exports/signatures/${fileType}?pollId=${pollId}&onlyVoters=${onlyVoters}`,
      headers
    )

    dispatch(setAppLoading(false))
    if (response.error) {
      dispatch(setErrorStatus(response.error))
      return rejectWithValue(response.error.statusCode)
    }
    downloadFile(response)
  }
)
