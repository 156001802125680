import { useCallback, useEffect, useRef } from 'react'
import { useAppDispatch, useAppSelector } from '../../init/store'
import { CORRESPONDANCE_ROLES } from '../roles/constants'
import { requestPresentResolution } from '../userResolutions/services'

const NOT_ROLES_PRESENT = [
  CORRESPONDANCE_ROLES.admin,
  CORRESPONDANCE_ROLES.supervisor,
  CORRESPONDANCE_ROLES.animator,
]

export const useUserPresentResolution = () => {
  const dispatch = useAppDispatch()

  const timerRef = useRef<NodeJS.Timer | null>(null)

  const meRole = useAppSelector((state) => state.auth.role)
  const isLogged = useAppSelector((state) => !!state.auth.token)
  const isSealed = useAppSelector((state) => state.sealing.isSealed)
  const isProxied = useAppSelector((state) => !!state.auth.isProxied)
  const isCSE = useAppSelector((state) => state.configuration.type === 'CSE')

  const setPresentInRes = useCallback(() => dispatch(requestPresentResolution()), [dispatch])

  useEffect(() => {
    if (timerRef.current) clearInterval(timerRef.current)
    if (!isCSE && isSealed && !isProxied && isLogged && !NOT_ROLES_PRESENT.includes(meRole)) {
      setPresentInRes()
      timerRef.current = setInterval(setPresentInRes, 10000)
    }
    return () => {
      if (timerRef.current) clearInterval(timerRef.current)
    }
  }, [isCSE, isLogged, isProxied, isSealed, meRole, setPresentInRes])
}
