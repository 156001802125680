import { createAsyncThunk } from '@reduxjs/toolkit'
import { getCookie, setCookie } from '../../utils/security/cookies'
import { isAllConfigTheSame } from '../../utils/pollConfig/isAllConfigTheSame'
import { getAxios, getTokenHeaders, patchAxios, setAxios } from '../../utils/lib/requestAxios'
import { setAppLoading, setErrorStatus } from '../app/redux'
import { ELECTION_ROUND } from '../../utils/constants/ElectionConstants'
import { downloadFile } from '../../utils/lib/downloadFile'
import { requestModes } from '../appConfig/services'
import { AppTypes } from '../../utils/types/AppStatus'

// Users request by search or/and by roleIds
export const requestGetConfigInfos = createAsyncThunk<any, undefined>(
  'configuration/requestGetConfigInfos',
  async (_, { rejectWithValue, dispatch }) => {
    const response: any = await getAxios(`/platform-conf`, getTokenHeaders())
    dispatch(setAppLoading(false))
    if (response.error) {
      return rejectWithValue(response.error.response.status)
    } else {
      return {
        data: response.data,
      }
    }
  }
)

//  request if third party is available
export const requestCheckThirdPartyAvailable = createAsyncThunk<any, undefined>(
  'thirdParty/requestCheckThirdPartyAvailable',
  async (_, { rejectWithValue, dispatch }) => {
    const response: any = await getAxios(`/thirdParty`, getTokenHeaders())
    dispatch(setAppLoading(false))
    if (response.error) {
      return rejectWithValue(response.error.response.status)
    } else {
      return {
        data: response.data,
      }
    }
  }
)

export const requestCheckThirdPartyAvailableStates = (builder) => {
  builder.addCase(requestCheckThirdPartyAvailable.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(requestCheckThirdPartyAvailable.fulfilled, (state, { payload }) => {
    const value = payload.data

    state.thirdPartyAvailable = value
  })

  builder.addCase(requestCheckThirdPartyAvailable.rejected, (state) => {
    state.isLoading = false
  })
}

export const requestGetConfigInfosStates = (builder) => {
  let mode = ''

  builder.addCase(requestModes.fulfilled, (state, { payload }) => {
    mode = payload.mode
  })

  builder.addCase(requestGetConfigInfos.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(requestGetConfigInfos.fulfilled, (state, { payload }) => {
    const data = payload.data

    // logging time is not needed because we are now blocking-logging user in additional

    // const loggedTime = getCookie('logged_at')

    if (mode === AppTypes.PROD || mode === AppTypes.NORMAL) {
      let newClosing = new Date(
        new Date(data.closingDate).setMinutes(
          new Date(data.closingDate).getMinutes() + data.additionalVoteTime
        )
      )

      // if (loggedTime && Number(new Date(loggedTime)) < Number(new Date(data.closingDate))) {
      //   newClosing.setMinutes(newClosing.getMinutes() + data.additionalVoteTime)
      // }

      data.closingTime = newClosing.toISOString()
    } else {
      let newClosing = new Date(
        new Date(data.closingDateSimulation).setMinutes(
          new Date(data.closingDateSimulation).getMinutes() + data.additionalVoteTime
        )
      )
      // if (
      //   loggedTime &&
      //   Number(new Date(loggedTime)) < Number(new Date(data.closingDateSimulation))
      // ) {
      //   newClosing.setMinutes(newClosing.getMinutes() + data.additionalVoteTime)
      // }

      data.closingTime = newClosing.toISOString()
    }

    state.isLoading = false
    state.config = payload.data
    state.type = payload.data?.type
    setCookie('TYPE', payload.data?.type)
  })

  builder.addCase(requestGetConfigInfos.rejected, (state, payload) => {
    state.isLoading = false
  })
}

export const requestPatchConfigInfos = createAsyncThunk<any, any>(
  'configuration/requestPatchConfigInfos',
  async ({ config }, { rejectWithValue }) => {
    const response: any = await patchAxios(`/platform-conf`, config, getTokenHeaders())

    if (response.error) {
      return rejectWithValue(response.error.response.status)
    } else {
      return {
        data: response.data,
      }
    }
  }
)

export const requestUpdateConfigInfos = createAsyncThunk<any, any>(
  'configuration/requestPatchConfigInfos',
  async ({ config }, { rejectWithValue }) => {
    const response: any = await patchAxios(`/platform-conf/update`, config, getTokenHeaders())

    if (response.error) {
      return rejectWithValue(response.error.response.status)
    } else {
      return {
        data: response.data,
      }
    }
  }
)

export const requestPatchConfigInfosStates = (builder) => {
  builder.addCase(requestPatchConfigInfos.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(requestPatchConfigInfos.fulfilled, (state, { payload }) => {
    state.isLoading = false
    state.config = {
      ...state.config,
      ...state.configTmp,
    }
    state.configTmp = undefined
  })

  builder.addCase(requestPatchConfigInfos.rejected, (state, payload) => {
    state.isLoading = false
  })
}

export const requestVoteInfos = createAsyncThunk<any, any>(
  'configuration/requestVoteInfos',
  async ({ establishments }, { rejectWithValue }) => {
    const establishmentsIdsStringified = establishments
      .map(({ id }) => id)
      .join('&establishmentIds[]=')

    if (!establishmentsIdsStringified?.length) {
      return null
    }

    const response: any = await getAxios(
      `/elections?establishmentIds[]=${establishmentsIdsStringified}`,
      getTokenHeaders()
    )

    if (response.error) {
      return rejectWithValue(response.error.response.status)
    } else {
      return {
        data: response.data,
        establishments,
      }
    }
  }
)

export const requestVoteInfosStates = (builder) => {
  builder.addCase(requestVoteInfos.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(requestVoteInfos.fulfilled, (state, { payload }) => {
    state.isLoading = false
    if (!payload) return
    state.configByEstablishement = payload.establishments.map((establ) => {
      const config = payload.data.find(({ establishmentId }) => establishmentId === establ.id) || {}
      return {
        id: establ.id,
        name: establ.name,
        ...config,
      }
    })
    state.configByEstablishement.push(
      isAllConfigTheSame(state.configByEstablishement)
        ? {
            ...state.configByEstablishement[0],
            id: 'all',
            establishmentId: 'all',
            isHidden: true,
          }
        : {
            id: 'all',
            establishmentId: 'all',
            isHidden: true,
          }
    )
  })

  builder.addCase(requestVoteInfos.rejected, (state, payload) => {
    state.isLoading = false
  })
}

const setManyElections = async (config, establishmentIds, logo, headers): Promise<any> => {
  const body: any = {
    establishmentIds,
    title: config.title,
    subTitle: config.subTitle,
    supportPhone: config.supportPhone,
    supportEmail: config.supportEmail,
  }
  if (typeof logo === 'undefined' && typeof config.logoUrl === 'string') {
    body.logoUrl = config.logoUrl
  } else if (typeof logo !== 'undefined') {
    body.image = logo
  } else body.logoUrl = null

  return await setAxios(`/elections/many`, body, headers)
}

export const requestSetVoteInfos = createAsyncThunk<any, any>(
  'configuration/requestSetVoteInfos',
  async ({ voteTmpConfig, files, isAllEstablishments = undefined }, { rejectWithValue }) => {
    if (!voteTmpConfig) return {}
    const keys = isAllEstablishments ? ['all'] : Object.keys(voteTmpConfig)
    const establishmentKeys = isAllEstablishments
      ? ['all']
      : Object.values(voteTmpConfig).map(
          ({ establishmentId, id }: any) => (establishmentId || id) + ''
        )

    const headers = {
      headers: {
        ...getTokenHeaders().headers,
        'Content-Type': 'multipart/form-data',
      },
    }
    let data: any = []

    for (let i = 0; i < keys.length; i++) {
      const config = voteTmpConfig[keys[i]]
      const logo = files[keys[i]]
      const response = await setManyElections(
        config,
        isAllEstablishments
          ? JSON.stringify(isAllEstablishments)
          : JSON.stringify([Number(establishmentKeys[i])]),
        logo,
        headers
      )
      if (response.error) {
        data.push(rejectWithValue(response.error.response.status))
      } else {
        data = data.concat(response.data)
      }
    }
    return { data, isAllTheSame: !!isAllEstablishments }
  }
)

export const requestSetVoteInfosStates = (builder) => {
  builder.addCase(requestSetVoteInfos.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(requestSetVoteInfos.fulfilled, (state, { payload }) => {
    state.isLoading = false
    state.voteTmp = {}
    state.configByEstablishement = state.configByEstablishement.map((establ) => {
      if (establ.id === 'all') {
        if (payload.isAllTheSame) {
          return {
            ...payload.data[0],
            id: 'all',
            establishmentId: 'all',
            isHidden: true,
          }
        } else
          return {
            id: 'all',
            establishmentId: 'all',
            isHidden: true,
          }
      } else {
        const config = payload.data.find(({ id }) => id === establ.id) || establ
        return {
          id: establ.id,
          name: establ.name,
          ...config,
        }
      }
    })
  })

  builder.addCase(requestSetVoteInfos.rejected, (state, payload) => {
    state.isLoading = false
  })
}

export const requestIdRules = createAsyncThunk<any>(
  'configuration/requestIdRules',
  async (_, { rejectWithValue }) => {
    const response: any = await getAxios('/id-rules', getTokenHeaders())

    if (response.error) {
      return rejectWithValue(response.error.response.status)
    } else {
      return response.data
    }
  }
)

export const requestIdRulesStates = (builder) => {
  builder.addCase(requestIdRules.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(requestIdRules.fulfilled, (state, { payload }) => {
    state.isLoading = false
    state.idRules = payload
  })

  builder.addCase(requestIdRules.rejected, (state, payload) => {
    state.isLoading = false
  })
}

export const requestSetIdRules = createAsyncThunk<any, any>(
  'configuration/requestSetIdRules',
  async (params, { rejectWithValue }) => {
    const response: any = await patchAxios('/id-rules', params, getTokenHeaders())

    if (response.error) {
      return rejectWithValue(response.error.response.status)
    } else {
      return response.data
    }
  }
)

export const requestSetIdRulesStates = (builder) => {
  builder.addCase(requestSetIdRules.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(requestSetIdRules.fulfilled, (state, { payload }) => {
    state.isLoading = false
    state.idRules = payload
  })

  builder.addCase(requestSetIdRules.rejected, (state, payload) => {
    state.isLoading = false
  })
}

// Reset for 2n round
export const requestResetFor2ndRound = createAsyncThunk<any, undefined>(
  'appConfig/requestResetFor2ndRound',
  async (_, { rejectWithValue, dispatch }) => {
    dispatch(setAppLoading(true))
    const response: any = await setAxios('/platform/switch-to-second-round', {}, getTokenHeaders())
    if (response.error) {
      dispatch(setAppLoading(false))
      dispatch(setErrorStatus({ message: response.error.response.data.message }))
      return rejectWithValue(response.error.response.status)
    }

    //  -- Reset to prod mode
    // const response2: any = await setAxios('/modes/production', undefined, getTokenHeaders())
    // if (response2.error) {
    //   if (response2.error.response.status === 400) {
    //     dispatch(setErrorStatus({ message: response2.error.response.data.message }))
    //   }
    //   return rejectWithValue(response2.error.response.status)
    // }
    window.location.reload()
    dispatch(setAppLoading(false))
  }
)

// Create and Export archive
export const requestDownloadArchive = createAsyncThunk<any, { currentRound: ELECTION_ROUND }>(
  'appConfig/requestDownloadArchive',
  async ({ currentRound = 'FIRST' }, { rejectWithValue, dispatch }) => {
    dispatch(setAppLoading(true))
    /*const responseCreate: any = await setAxios(`/platform/archives`, {}, getTokenHeaders())

    if (responseCreate.error) {
      dispatch(setAppLoading(false))
      dispatch(setErrorStatus({ message: responseCreate.error.response.message }))
      return rejectWithValue(responseCreate.error.response.status)
    }*/

    const headers: any = {
      responseType: 'blob',
      ...getTokenHeaders(),
    }

    const response: any = await getAxios(`/platform/archives/${currentRound}/file`, headers)
    if (response.error) {
      dispatch(setAppLoading(false))
      dispatch(setErrorStatus(response.error))
      return rejectWithValue(response.error.statusCode)
    }
    downloadFile(response)
    dispatch(setAppLoading(false))
  }
)
