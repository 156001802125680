import { useCallback, useEffect } from 'react'
import { requestProfile } from './services'
import { useAppDispatch, useAppSelector } from '../../init/store'

export const useProfile = () => {
  const dispatch = useAppDispatch()

  const userId = useAppSelector((state) => state.profile.userId)
  const user = useAppSelector((state) => state.users.userById[userId])

  const getProfile = useCallback(() => dispatch(requestProfile()), [dispatch])

  useEffect(() => {
    if (!user || !userId) {
      getProfile()
    }
  }, [getProfile, user, userId])

  return {
    user: user || {},
    userId,
    getProfile,
  }
}

export const useUserId = () => {
  const userId = useAppSelector((state) => state.profile.userId)

  return userId
}

export const useAssistanceInfos = () => {
  const isCSE = useAppSelector((state) => state.configuration.type === 'CSE')
  const supportEmailCSE = useAppSelector(
    (state) =>
      state.configuration.configByEstablishement.find((conf) => conf.supportEmail)?.supportEmail ||
      ''
  )

  const supportEmail = useAppSelector((state) => state.configuration.config?.supportEmail || '')
  const supportPhoneCSE = useAppSelector(
    (state) =>
      state.configuration.configByEstablishement.find((conf) => conf.supportPhone)?.supportPhone ||
      ''
  )
  const supportPhone = useAppSelector((state) => state.configuration.config?.supportPhone || '')

  return {
    supportEmail: isCSE ? supportEmailCSE : supportEmail,
    supportPhone: isCSE ? supportPhoneCSE : supportPhone,
  }
}
