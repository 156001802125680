import { lazy } from 'react'

// Lazy loading
const pages = {
  VotesRightsManagement: lazy(
    () => import('../../pages/VotesRightsManagement/VotesRightsManagement')
  ),
  ParticipationSignaturePage: lazy(
    () => import('../../pages/ParticipationSignature/ParticipationSignature')
  ),
  Home: lazy(() => import('../../pages/Home/Home')),
  Users: lazy(() => import('../../pages/Users/Users')),
  Login: lazy(() => import('../../pages/Login/Login')),
  Scrutins: lazy(() => import('../../pages/Scrutins/Scrutins')),
  Establishments: lazy(() => import('../../pages/Establishments/Establishments')),
  EstablishmentEdition: lazy(() => import('../../pages/EstablishmentEdition/EstablishmentEdition')),
  SealingPage: lazy(() => import('../../pages/Sealing/Sealing')),
  AdminList: lazy(() => import('../../pages/AdminList/AdminList')),
  AdminPoll: lazy(() => import('../../pages/AdminPoll/AdminPoll')),
  DemoLogin: lazy(() => import('../../pages/DemoLogin/DemoLogin')),
  SendCodes: lazy(() => import('../../pages/SendCodes/SendCodes')),
  RecordEdit: lazy(() => import('../../pages/RecordEdit/RecordEdit')),
  RecordList: lazy(() => import('../../pages/RecordList/RecordList')),
  ColorTheme: lazy(() => import('../../pages/ColorTheme/ColorTheme')),
  VotingLogs: lazy(() => import('../../pages/VotingLogs/VotingLogs')),
  AnimateVote: lazy(() => import('../../pages/AnimateVote/AnimateVote')),
  CellMembers: lazy(() => import('../../pages/CellMembers/CellMembers')),
  UserEdition: lazy(() => import('../../pages/UserEdition/UserEdition')),
  ListsPreview: lazy(() => import('../../pages/ListsPreview/ListsPreview')),
  ScrutinLists: lazy(() => import('../../pages/ScrutinLists/ScrutinLists')),
  SystemStatus: lazy(() => import('../../pages/SystemStatus/SystemStatus')),
  Verification: lazy(() => import('../../pages/Verification/Verification')),
  BallotReceipt: lazy(() => import('../../pages/BallotReceipt/BallotReceipt')),
  ResetPassword: lazy(() => import('../../pages/ResetPassword/ResetPassword')),
  VoteElections: lazy(() => import('../../pages/VoteElections/VoteElections')),
  ResolutionVote: lazy(() => import('../../pages/ResolutionVote/ResolutionVote')),
  ScrutinEdition: lazy(() => import('../../pages/ScrutinEdition/ScrutinEdition')),
  AdminResolution: lazy(() => import('../../pages/AdminResolution/AdminResolution')),
  FollowPasswords: lazy(() => import('../../pages/FollowPasswords/FollowPasswords')),
  PollControlVote: lazy(() => import('../../pages/PollControlVote/PollControlVote')),
  SendCodesSender: lazy(() => import('../../pages/SendCodesSender/SendCodesSender')),
  UserRoleEdition: lazy(() => import('../../pages/UserRoleEdition/UserRoleEdition')),
  FormatManagement: lazy(() => import('../../pages/FormatManagement/FormatManagement')),
  PgpKeysManagement: lazy(() => import('../../pages/PgpKeysManagement/PgpKeysManagement')),
  PollConfiguration: lazy(() => import('../../pages/PollConfiguration/PollConfiguration')),
  VoteCandidateList: lazy(() => import('../../pages/VoteCandidateList/VoteCandidateList')),
  VoteCSECandidates: lazy(() => import('../../pages/VoteCSECandidates/VoteCSECandidates')),
  CandidatesElection: lazy(() => import('../../pages/CandidatesElection/CandidatesElection')),
  AnimateVoteResults: lazy(() => import('../../pages/AnimateVoteResults/AnimateVoteResults')),
  HelpFilesManagement: lazy(() => import('../../pages/HelpFilesManagement/HelpFilesManagement')),
  ResolutionVoteBallot: lazy(() => import('../../pages/ResolutionVoteBallot/ResolutionVoteBallot')),
  ResolutionParticipation: lazy(
    () => import('../../pages/ResolutionParticipation/ResolutionParticipation')
  ),
  Maintenance: lazy(() => import('../../pages/Maintenance/Maintenance')),
}

export default pages
