import { createAsyncThunk } from '@reduxjs/toolkit'
import { getAxios, getTokenHeaders, setAxios } from '../../utils/lib/requestAxios'

export const requestRoles = createAsyncThunk<undefined, undefined>(
  'users/requestRoles',
  async (_, { rejectWithValue }) => {
    const response: any = await getAxios('/roles', getTokenHeaders())

    if (response.error) {
      return rejectWithValue(response.error.response.status)
    } else {
      return response.data
    }
  }
)

export const requestRolesStates = (builder) => {
  builder.addCase(requestRoles.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(requestRoles.fulfilled, (state, { payload: roles }) => {
    state.isLoading = false
    state.roles = roles
  })

  builder.addCase(requestRoles.rejected, (state, payload) => {
    state.isLoading = false
  })
}

export const requestPrivileges = createAsyncThunk<any, any>(
  'users/requestPrivileges',
  async ({ userIds }, { rejectWithValue }) => {
    const idsStringified = userIds.join('&userIds[]=')
    const response: any = await getAxios(
      `/users/aggregated-privileges?userIds[]=${idsStringified}`,
      getTokenHeaders()
    )

    if (response.error) {
      return rejectWithValue(response.error.response.status)
    } else {
      return { privileges: response.data, userIds }
    }
  }
)

export const requestPrivilegesStates = (builder) => {
  builder.addCase(requestPrivileges.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(requestPrivileges.fulfilled, (state, { payload }) => {
    state.isLoading = false
    state.privileges = payload.privileges
    state.userIds = payload.userIds
  })

  builder.addCase(requestPrivileges.rejected, (state, payload) => {
    state.isLoading = false
  })
}

export const requestSetPrivileges = createAsyncThunk<any, any>(
  'users/setPrivileges',
  async ({ userIds, privileges }, { rejectWithValue }) => {
    const idsStringified = userIds.join('&userIds[]=')
    const response: any = await setAxios(
      `/users/privileges?userIds[]=${idsStringified}`,
      privileges,
      getTokenHeaders()
    )

    if (response.error) {
      return rejectWithValue(response.error.response.status)
    } else {
      return response.data
    }
  }
)

export const requestSetPrivilegesStates = (builder) => {
  builder.addCase(requestSetPrivileges.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(requestSetPrivileges.fulfilled, (state, { payload: privileges }) => {
    state.isLoading = false
    state.privileges = undefined
    state.userIds = []
    state.tmpPrivileges = undefined
  })

  builder.addCase(requestSetPrivileges.rejected, (state, payload) => {
    state.isLoading = false
  })
}

export const requestResetRolePrivileges = createAsyncThunk<any, any>(
  'users/resetPrivileges',
  async ({ role, collegeIds }, { rejectWithValue }) => {
    const collegeIdsStringified = Object.entries(collegeIds)
      .filter(([_id, status]) => status === 'ENABLED')
      .map(([id]) => id)
      .join('&collegeIds[]=')

    const response: any = await setAxios(
      `/roles/${role}/privileges/reset-to-default?collegeIds[]=${collegeIdsStringified}`,
      null,
      getTokenHeaders()
    )

    if (response.error) {
      return rejectWithValue(response.error.response.status)
    } else {
      return { adminPrivileges: response.data }
    }
  }
)

export const requestResetRolePrivilegesStates = (builder) => {
  builder.addCase(requestResetRolePrivileges.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(requestResetRolePrivileges.fulfilled, (state, { payload }) => {
    state.isLoading = false
    state.tmpPrivileges = undefined
    state.adminPrivileges = payload.adminPrivileges
  })

  builder.addCase(requestResetRolePrivileges.rejected, (state, payload) => {
    state.isLoading = false
  })
}

export const requestAdminPrivileges = createAsyncThunk<any, any>(
  'users/requestAdminPrivileges',
  async ({ role, collegeIds }, { rejectWithValue }) => {
    let collegeIdsStringified = ''

    if (Array.isArray(collegeIds) && collegeIds.length) {
      collegeIdsStringified = `?collegeIds[]=${collegeIds.join('&collegeIds[]=')}`
    }

    const response: any = await getAxios(
      `/roles/${role}/aggregated-privileges${collegeIdsStringified}`,
      getTokenHeaders()
    )

    if (response.error) {
      return rejectWithValue(response.error.response.status)
    } else {
      return response.data
    }
  }
)

export const requestAdminPrivilegesStates = (builder) => {
  builder.addCase(requestAdminPrivileges.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(requestAdminPrivileges.fulfilled, (state, { payload: adminPrivileges }) => {
    state.isLoading = false
    state.adminPrivileges = adminPrivileges
  })

  builder.addCase(requestAdminPrivileges.rejected, (state, payload) => {
    state.isLoading = false
  })
}

export const requestSetRolePrivileges = createAsyncThunk<any, any>(
  'roles/setPrivileges',
  async ({ role, collegeIds, privileges }, { rejectWithValue }) => {
    const collegeIdsStringified = collegeIds.length
      ? '?collegeIds[]=' + collegeIds.join('&collegeIds[]=')
      : ''
    const response: any = await setAxios(
      `/roles/${role}/privileges${collegeIdsStringified}`,
      privileges,
      getTokenHeaders()
    )

    if (response.error) {
      return rejectWithValue(response.error.response.status)
    } else {
      return response.data
    }
  }
)

export const requestSetRolePrivilegesStates = (builder) => {
  builder.addCase(requestSetRolePrivileges.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(requestSetRolePrivileges.fulfilled, (state, { payload: adminPrivileges }) => {
    state.isLoading = false
  })

  builder.addCase(requestSetRolePrivileges.rejected, (state, payload) => {
    state.isLoading = false
  })
}
