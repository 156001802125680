import { useCallback } from 'react'
import {
  requestRandomRole,
  requestUsers,
  requestSelectUser,
  requestStartDemo,
  requestStopDemo,
} from './services'
import { setOverrideUsers, setUserId } from './redux'
import { requestStatus } from '../appConfig/services'
import { requestInitSealingStatus } from '../sealing/services'
import { useAppDispatch, useAppSelector } from '../../init/store'
import { requestGetConfigInfos } from '../configuration/services'
import { clearSessionStorage } from '../../utils/security/localStorage'
import { usePrivileges } from '../auth/hooks'

// Demo
export const useIsDemo = () => {
  const isDemo = useAppSelector((state) => state.demo.isDemo)
  return isDemo
}

export const useDemo = () => {
  const dispatch = useAppDispatch()
  const isDemo = useAppSelector((state) => state.demo.isDemo)
  const isLoading = useAppSelector((state) => state.demo.isLoading)
  const { CONFIGURE_ELECTION } = usePrivileges()
  const currentUserId = useAppSelector((state) => state.demo.currentUserId)
  const currentUser = useAppSelector((state) => state.demo.userById[currentUserId])

  const stopDemo = useCallback(() => dispatch(requestStopDemo()), [dispatch])
  const startDemo = useCallback(
    (errorCallback: Function) => dispatch(requestStartDemo({ errorCallback })),
    [dispatch]
  )

  const selectUser = useCallback(
    (userId?: string) => {
      if (userId) {
        dispatch(requestSelectUser({ userId }))
      } else {
        clearSessionStorage()
        dispatch(setUserId(null))
        dispatch(requestGetConfigInfos())
        dispatch(requestStatus())
        dispatch(requestInitSealingStatus({ isAdmin: CONFIGURE_ELECTION }))
      }
    },
    [dispatch, CONFIGURE_ELECTION]
  )

  return { startDemo, stopDemo, selectUser, isDemo, isLoading, currentUser, currentUserId }
}

// Users
export const useUsersByRole = (role) => {
  const dispatch = useAppDispatch()
  const users = useAppSelector((state) => state.demo.userIdsByRole[role] || [])

  const getUsersByRoles = useCallback(
    (search, params = {}) => dispatch(requestUsers({ search, roles: [role], params, clear: true })),
    [role, dispatch]
  )

  return { users, getUsersByRoles }
}

export const useRandomRole = (role: string) => {
  const dispatch = useAppDispatch()
  const getRandomRole = useCallback(
    (filters = {}) => dispatch(requestRandomRole({ role, filters })),
    [role, dispatch]
  )

  return getRandomRole
}

// Get and use a selection that can be overrided by a randomizer
//   (aka useRandomRole or getAllRandom)
export const useRandomOverride = () => {
  const dispatch = useAppDispatch()

  const roles = useAppSelector((state) => state.roles.roles)
  const overrideUsers = useAppSelector((state) => state.demo.userIdsByRoleSelection)

  const getAllRandom = useCallback(
    (filters) => {
      roles?.forEach((role) => {
        dispatch(requestRandomRole({ filters, role, clear: true }))
      })
    },
    [dispatch, roles]
  )

  const setUsersOverride = useCallback((users) => dispatch(setOverrideUsers(users)), [dispatch])

  return { overrideUsers, setUsersOverride, getAllRandom }
}
