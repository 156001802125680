import { createSlice } from '@reduxjs/toolkit'

// Services
import { requestEstablishmentStates, requestEstablishmentsStates } from '../establishments/services'

// Utils

// Define a type for the slice state
export type EstablishmentsState = {
  establishmentIds: Array<number>
  establishmentById: { [key: number]: any }
  isLoading?: boolean
}

// Define the initial state using that type
const initialState: EstablishmentsState = {
  establishmentIds: [],
  establishmentById: {},
}

export const establishments = createSlice({
  name: 'establishments',
  initialState,
  reducers: {
    UpdateEstablishment: (state, { payload: { establishmentId, data } }) => {
      state.establishmentById[establishmentId] = {
        ...state.establishmentById[establishmentId],
        ...data,
      }
    },
  },
  extraReducers: (builder) => {
    requestEstablishmentsStates(builder)
    requestEstablishmentStates(builder)
  },
})

export const { UpdateEstablishment } = establishments.actions
