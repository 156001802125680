import moment from 'moment-timezone'
import 'moment/locale/fr'

export const DEFAULT_TIMEZONE = 'Europe/Paris'

/**
 * Pads a number with a leading zero if it is less than 10
 */
export function padTwoDigits(value: number) {
  return value.toString().padStart(2, '0')
}

export function toTwoDigitMonth(monthIndex) {
  return padTwoDigits(monthIndex + 1)
}

/**
 * Returns a string representing the date in the format dd/mm/yyyy
 */
export function toDisplayDate(date: Date, setFranceLocale = false) {
  if (setFranceLocale) {
    return moment(date).locale('fr').tz(DEFAULT_TIMEZONE).format('DD/MM/YYYY')
  }
  return moment(date).locale('fr').format('DD/MM/YYYY')
}

/**
 * Returns a string representing the time in the format H24hMM
 */
export function toDisplayTime(date: Date, separator: string = 'h', setFranceLocale = false) {
  if (setFranceLocale) {
    return moment(date)
      .locale('fr')
      .tz(DEFAULT_TIMEZONE)
      .format('HH[' + separator + ']mm')
  }
  return moment(date)
    .locale('fr')
    .format('HH[' + separator + ']mm')
}

/**
 * Returns a string representing the date in the format yyyy-mm-dd.
 */
export const getUTCString = (date: Date, setFranceLocale = false) => {
  if (setFranceLocale) {
    return moment(date).locale('fr').tz(DEFAULT_TIMEZONE).format('YYYY-MM-DD')
  }
  return moment(date).locale('fr').format('YYYY-MM-DD')
}

export function toStringDate(
  date: Date,
  format: string,
  timezone: string | null = null,
  addTimezone = false
) {
  if (timezone) {
    //Code à décommenté si on ne veut afficher le fuseau horaire que s'il est différent de celui paramétré
    return (
      moment(date).locale('fr').tz(timezone).format(format) +
      (addTimezone ? ' (' + timezone + ')' : '')
    )
  }
  return moment(date).locale('fr').format(format)
}
