export enum STATUS_ENUM {
  starting = 'starting',
  open = 'open',
  closed = 'closed',
}

export const getStatus = (
  today: number,
  openAt: Date | null,
  closesAt: Date | null,
  isSealed: boolean
) => {
  if (!openAt) return null
  if (!isSealed) {
    return STATUS_ENUM.starting
  } else {
    if (Number(today) < Number(openAt)) {
      return STATUS_ENUM.starting
    } else if (closesAt && Number(today) > Number(closesAt)) {
      return STATUS_ENUM.closed
    } else {
      return STATUS_ENUM.open
    }
  }
}
