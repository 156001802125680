import React from 'react'
import Icon from '../Icon/Icon'

import './Tooltip.scss'
import IconTypes from '../../utils/types/IconTypes'

interface TooltipType {
  label: string | string[]
  className?: string
  children?: any
  disabled?: boolean
  vertical?: 'top' | 'bottom'
  horizontal?: 'left' | 'right' | 'center'
}

const Tooltip: React.FC<TooltipType> = ({
  label,
  className,
  disabled,
  children,
  horizontal = 'right',
  vertical = 'top',
}) => (
  <div id='Tooltip' className={className}>
    {!children && <Icon className='toolTipIcon' icon={IconTypes.INFO} />}
    {!!children && children}
    {!disabled && (
      <div className={`tooltipInfo ${horizontal} ${vertical}`}>
        {typeof label === 'string' && <>{label}</>}
        {typeof label === 'object' &&
          label.map((item, index) => {
            return <div key={index}>{item}</div>
          })}
      </div>
    )}
  </div>
)

export default React.memo(Tooltip)
