import { IonHeader, IonModal } from '@ionic/react'
import { useCallback, useEffect, useRef, useState } from 'react'
import useLocalize from '../../locales/hook'

import './VoteInfoConfirmationModal.scss'
import IconTypes from '../../utils/types/IconTypes'
import Icon from '../../atoms/Icon/Icon'
import Button from '../../atoms/Button/Button'
import TextInput from '../../atoms/TextInput/TextInput'
import { useValidationParams } from '../../redux/configuration/hooks'
import { useMandatoryVerification } from '../../redux/scrutins/hooks'
import Image from '../../atoms/Image/Image'

const uniqId = require('shortid')

interface Props {
  visible: boolean
  setVisible: (value: boolean) => void
  onConfirm: (params: any) => void
}

const VoteInfoConfirmationModal: React.FC<Props> = ({ visible, setVisible, onConfirm }) => {
  const t = useLocalize()
  const id = useRef('modal_' + uniqId.generate())
  const { mandatoryVerifications } = useMandatoryVerification()
  const { modalType, personalInformation: validPersonalInfo } = useValidationParams()
  const [mandatory, setMandatory] = useState(mandatoryVerifications)
  const [personalInfo, setPersonalInfo] = useState('')
  const [password, setPassword] = useState('')

  useEffect(() => {
    setMandatory(mandatoryVerifications)
  }, [mandatoryVerifications])

  const resetAll = useCallback(() => {
    setMandatory({
      personalInformation: false,
      password: false,
    })
    setPersonalInfo('')
    setPassword('')
  }, [])

  const handleCloseModal = useCallback(
    (e) => {
      e.stopPropagation()
      setVisible(false)
      resetAll()
    },
    [resetAll, setVisible]
  )

  const handleConfirm = useCallback(
    (e) => {
      e.stopPropagation()
      const body: any = {}

      if (['personalInfo', 'both'].includes(modalType)) {
        body.personalInformation = personalInfo.trim()
      }
      if (['password', 'both'].includes(modalType)) {
        body.password = password.trim()
      }
      onConfirm(body)
    },
    [modalType, onConfirm, password, personalInfo]
  )

  return (
    <IonModal
      id={id.current}
      className='VoteInfoConfirmationModal'
      isOpen={visible}
      onWillDismiss={handleCloseModal}
    >
      <IonHeader class='ion-no-border' className='warningHeader'>
        <div className='title'>{t(`confirm_vote_with_${modalType}_title`)}</div>
        <Icon className='icon' icon={IconTypes.X} onClick={handleCloseModal} />
      </IonHeader>
      <div className='warningContent'>
        <div className='message'>
          <div className='iconSide'>
            <Icon icon={IconTypes.INFO} />
          </div>
          <div className='info'>
            <div className='desc'>{t(`confirm_vote_with_${modalType}`)}</div>
          </div>
        </div>
        <Image src='confirm-vote' />
        {['personalInfo', 'both'].includes(modalType) && (
          <div className='fieldContainer'>
            <TextInput
              label={t('personal_info') + (validPersonalInfo ? ' *' : '')}
              autocomplete='off'
              valueKey='username'
              error={mandatory.personalInformation || mandatory.password}
              onChange={setPersonalInfo}
              type='password'
            />
          </div>
        )}
        {['password', 'both'].includes(modalType) && (
          <div className='fieldContainer'>
            <TextInput
              label={t('password') + ' *'}
              autocomplete='off'
              valueKey='username'
              error={mandatory.personalInformation || mandatory.password}
              onChange={setPassword}
              type='password'
            />
          </div>
        )}
      </div>
      <div className='buttonsBox'>
        <Button label={t('confirm_and_vote')} fill onClick={handleConfirm} />
      </div>
    </IonModal>
  )
}

export default VoteInfoConfirmationModal
