import ROUTES from '../../constants/routes'
import IconTypes from '../../../utils/types/IconTypes'

export type HeaderConfigType = {
  name: string
  icon?: string
  path?: string
  children?: HeaderConfigType[]
  AdminOnly?: boolean
  rights?: {
    [key: string]: boolean
  }
  voteStarted?: boolean
}

const headerConfig: HeaderConfigType[] = [
  {
    name: 'home',
    path: ROUTES.HOME,
    icon: IconTypes.HOME,
  },
  {
    name: 'system_state',
    path: ROUTES.SYSTEM_STATUS,
    icon: IconTypes.POCKET,
  },
  {
    name: 'verification',
    path: ROUTES.VERIFICATION,
    icon: IconTypes.CHECK_CIRCLE,
    rights: {
      VOTE: true,
    },
    voteStarted: true,
  },
]

export default headerConfig
