import { useCallback, useEffect, useMemo } from 'react'
import {
  requestEstablishmentsData,
  requestImportStatus,
  requestModes,
  requestPlatformHashes,
  requestNewHashes,
  requestSetConfig,
  requestSetProd,
  requestDownloadHashes,
  requestStatus,
  requestMaintenanceMode,
} from './services'
import { useAppDispatch, useAppSelector } from '../../init/store'
import { useIonRouter } from '@ionic/react'
import { isLoggedRoutes } from '../../routes/constants/routes'
import { requestGetConfigInfos } from '../configuration/services'

export const useLoginData = () => {
  const dispatch = useAppDispatch()
  const requestLoginData = useCallback(() => dispatch(requestEstablishmentsData()), [dispatch])

  const isLoading = useAppSelector((state) => state.appConfig.loaders.establishments)
  const establishments = useAppSelector((state) => state.appConfig.establishments)
  const formattedEstablishments = useAppSelector((state) => state.appConfig.formattedEstablishments)
  const collegeIds = useAppSelector((state) => state.appConfig.collegeIds)
  const requested = useAppSelector((state) => state.appConfig.requested?.establishments)

  useEffect(() => {
    if (!requested) {
      requestLoginData()
    }
  }, [requestLoginData, requested])

  return {
    isLoading,
    establishments: establishments?.length ? establishments : null,
    formattedEstablishments,
    collegeIds,
  }
}

export const useEstablishments = () => {
  const establishments = useAppSelector((state) => state.appConfig.establishments) || []
  return establishments
}

export const useIsImported = () => {
  const dispatch = useAppDispatch()
  const {
    routeInfo: { pathname },
  } = useIonRouter()
  const isLogged = useAppSelector((state) => !!state.auth.token)
  const isLoading = useAppSelector((state) => state.appConfig.loaders.import)

  const isImported = useAppSelector((state) => state.appConfig.isImported)

  const requestStatus = useCallback(() => dispatch(requestImportStatus()), [dispatch])

  useEffect(() => {
    if (isLogged && isLoggedRoutes()) {
      requestStatus()
    }
  }, [isLogged, pathname, requestStatus])

  return { isImported, isLoading }
}

export const useAppMode = () => {
  const dispatch = useAppDispatch()
  /*const {
    routeInfo: { pathname },
  } = useIonRouter()*/
  const isLogged = useAppSelector((state) => !!state.auth.token)
  const isProd = useAppSelector((state) => state.appConfig.isProd)
  const isDemo = useAppSelector((state) => state.demo.isDemo)

  const requestAppModes = useCallback(() => dispatch(requestModes()), [dispatch])
  const setProdStatus = useCallback(
    (errorCallback) => dispatch(requestSetProd({ errorCallback })),
    [dispatch]
  )
  const setConfigStatus = useCallback(() => dispatch(requestSetConfig()), [dispatch])

  useEffect(() => {
    if (isLogged && isLoggedRoutes()) {
      requestAppModes()
    }
  }, [isLogged, /*pathname, */ requestAppModes])

  return { isDemo, isProd, setProdStatus, setConfigStatus }
}

export const useIsReadOnly = (pollId?: string) => {
  /*const {
    routeInfo: { pathname },
  } = useIonRouter()*/
  const isProxied = useAppSelector((state) =>
    pollId ? !!state.auth.proxiedPollIds?.includes(pollId) : false
  )
  return /*useMemo(() => pathname.startsWith(LINK_ROLE.MOD), [pathname]) ||*/ isProxied
}

// PlatformInfo
export const usePlatformStatus = () => {
  const status = useAppSelector((state) => state.appConfig.status)

  return status
}

export const useMaintenanceMode = () => {
  const dispatch = useAppDispatch()

  const handleMaintenance = useCallback(() => dispatch(requestMaintenanceMode()), [dispatch])
  const status = useAppSelector((state) => state.appConfig.maintenance)

  return { status, handleMaintenance }
}

export const useProgressiveType = () => {
  const type = useAppSelector((state) => state.configuration?.type)
  const progressiveVoteStatus = useAppSelector(
    (state) => state.appConfig.status.PROGRESSIVE_VOTE_STATUS
  )

  const openResolution = type === 'Other' && progressiveVoteStatus

  return openResolution
}

export const usePlatformHashes = () => {
  const dispatch = useAppDispatch()
  const hashes = useAppSelector((state) => state.appConfig?.hashes)

  const setNewHashes = useCallback(() => dispatch(requestNewHashes()), [dispatch])
  const downloadHashes = useCallback(() => dispatch(requestDownloadHashes()), [dispatch])
  const getRequestHashes = useCallback(() => dispatch(requestPlatformHashes()), [dispatch])

  useMemo(() => {
    getRequestHashes()
  }, [getRequestHashes])
  return { hashes: hashes || [], setNewHashes, downloadHashes }
}

export const useDidHashesChange = () => {
  const hashes = useAppSelector((state) => state.appConfig.hashes)

  const didHashesChange = useMemo(() => {
    if (typeof hashes == 'object' && typeof hashes.length === 'number')
      return hashes.some((item) => item.current?.hash !== item.old?.hash)
  }, [hashes])
  return didHashesChange
}

export const useGetStatus = () => {
  const dispatch = useAppDispatch()

  const getStatus = useCallback(() => {
    dispatch(requestStatus())
    dispatch(requestGetConfigInfos())
  }, [dispatch])

  return getStatus
}
